import { ENDPOINTS } from "config/Constants";
import { IPurchaseOrder } from "interfaces/purchaseOrder";
import { Api } from "services/Api";

class PurchaseOrderServiceClass {
  fetchPurchaseOrder = async (
    id: string
  ): Promise<IPurchaseOrder | undefined> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.GET_PURCHASE_ORDERS}&filter_id=${id}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching purchase order", error);
    }
  };

  fetchStore = async (
    id: string
  ): Promise<IPurchaseOrder | undefined> => {
    try {
      const response = await Api.get(
        `/stores/0`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching purchase order", error);
    }
  };
}

const PurchaseOrderService = new PurchaseOrderServiceClass();
Object.freeze(PurchaseOrderService);
export default PurchaseOrderService;
