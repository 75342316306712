import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import { SIDERWIDTH } from "config/Constants";
import { useStoreState } from "hooks";
import { Outlet } from "react-router";
import MainHeader from "./components/header";
import MainSidebar from "./components/mainsidebar";
import "./privateLayout.scss";

function PrivateLayout() {
  const isSiderBarCollapsed = useStoreState(
    (state) => state.globalConfig.isSiderBarCollapsed
  );
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);

  return (
    <Layout className="main-layout">
      <MainSidebar />
      <Layout
        className="content-layout"
        style={{
          marginLeft: isSiderBarCollapsed ? 0 : isMobile ? 0 : SIDERWIDTH,
        }}
      >
        <MainHeader />
        <Content className="outlet-layout">
          <Outlet />
        </Content>

        <Footer style={{ textAlign: "center" }}>FreshKaka © 2021</Footer>
      </Layout>
    </Layout>
  );
}

export default PrivateLayout;
