export interface ISupplier {
  id: string;
  name: string;
  phone: string;
  address: string;
  email: string;
  company: string;
  taxid: string;
}

export interface IStats {
  total_po_count: IStatsItem;
  due_po_count: IStatsItem;
  total_po_amount: IStatsItem;
  due_po_amount: IStatsItem;
  supplier_count: IStatsItem;
}

export interface IStatsItem {
  title: string;
  value: string;
  type?: string;
}

export enum EStatsType {
  AMOUNT = "amount",
}
