import React  from "react";
import PageHeading from "components/headings/headings";
import CategoryMenu from "./components/categoryMenu";

function Menu() {
  return (
    <div>
      <PageHeading label="Menu"></PageHeading>
      <CategoryMenu />
    </div>
  );
}

export default Menu;
