import { PlusOutlined } from "@ant-design/icons";
import { TableColumnsType } from "antd";
import GenericTable from "components/genericTable";
import { ENDPOINTS } from "config/Constants";
import { IAccount } from "interfaces/account";
import { IGenericTable, ITableDataTypes } from "interfaces/genericTable";
import React, { useRef, useState } from "react";
import AddAccount from "./addAccount";
import Currency from "components/currency";

const columns: TableColumnsType<ITableDataTypes> = [
  {
    title: "Account",
    dataIndex: "holdername",
    render: (name) => <span className="text-capitalize">{name}</span>,
  },
  {
    title: "Account No.",
    dataIndex: "accountnumber",
  },
  {
    title: "Balance",
    dataIndex: "lastbal",
    render: (name, record) => Currency(name),
  },
  {
    title: "Type",
    dataIndex: "account_type",
  },
];

function AccountsTable() {
  type GenericTableHandle = React.ElementRef<typeof GenericTable>;
  const tableRef = useRef<GenericTableHandle>(null);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const handleTableActions = (type: string) => {
    switch (type) {
      case "add": {
        setShowAddAccount(true);
      }
    }
  };

  const onClose = (account?: IAccount) => {
    setShowAddAccount(false);
    if (account) tableRef?.current?.refreshTable(true);
  };

  const [accountsTableConfig] = useState<IGenericTable<ITableDataTypes>>({
    columns: columns,
    endpoint: ENDPOINTS.GET_ACCOUNTS,
    tableActionCallback: handleTableActions,
    tableActions: [{ icon: <PlusOutlined />, title: "Add", type: "add" }],
    enablePagination: false,
    scroll: { x: 1000 },
  });

  return (
    <div>
      <GenericTable {...accountsTableConfig} ref={tableRef} />
      <AddAccount visible={showAddAccount} onClose={onClose} />
    </div>
  );
}

export default AccountsTable;
