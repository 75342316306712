import { useStoreState } from "hooks";
import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "routes/routes";
import "./App.less";

function App() {
  const { isLoggedIn, user } = useStoreState((state) => state.account);

  const routing = useRoutes(routes(isLoggedIn, user));
  return <>{routing}</>;
}

export default App;
