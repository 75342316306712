import { Col, Row } from "antd";
import React from "react";
import IMenuProduct from "../interface/product";
import ProductCard from "./productCard";

interface IProductGrid {
  products: IMenuProduct[];
  category: string;
}

function ProductsGrid({ products, category }: IProductGrid) {
  return (
    <>
      <div className="category-name">{category}</div>
      <Row gutter={32}>
        {products.map((product, index) => {
          return (
            <Col key={index} xs={6}>
              <ProductCard product={product} />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default ProductsGrid;
