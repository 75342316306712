import { Action, action, computed } from "easy-peasy";
import { Computed } from "easy-peasy";

export interface IGlobalConfig {
  isSiderBarCollapsed: boolean;
  windowSize: number;
  setIsSiderBarCollapsed: Action<IGlobalConfig, boolean>;
  setWindowSize: Action<IGlobalConfig, number>;
  isMobile: Computed<IGlobalConfig, boolean>;
}

const globalConfig: IGlobalConfig = {
  isSiderBarCollapsed: false,
  windowSize: window.innerWidth,
  setIsSiderBarCollapsed: action((state, payload) => {
    state.isSiderBarCollapsed = payload;
  }),
  setWindowSize: action((state, payload) => {
    state.windowSize = payload;
  }),
  isMobile: computed((state) => state.windowSize < 992),
};

export default globalConfig;
