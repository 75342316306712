import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  DatePicker,
  Radio,
  Table,
  Select,
} from "antd";
import { ENDPOINTS, DATE_FORMAT, DATABASE_DATE_FORMAT } from "config/Constants";
import { useStoreState } from "hooks";
import { IPurchaseOrder } from "interfaces/purchaseOrder";
import { useState, useEffect, useCallback } from "react";
import { Api } from "services/Api";
import SearchSupplier from "./components/searchSupplier";
import SearchProduct from "../searchProduct";
import { TSimpleProduct } from "interfaces/product";
import PurchaseItemCard from "./components/purchaseItemCard";
import moment from "moment";
import "./styles/index.scss";
import { ISupplier } from "interfaces/suppliers";
interface IAddPurchaseOrder {
  visible: boolean;
  onClose: (purcaseOrder?: IPurchaseOrder) => void;
  purchaseOrder?: IPurchaseOrder;
}

const { Option } = Select;
const defaultSelectValue = "null";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
};
/* eslint-enable no-template-curly-in-string */

const { TextArea } = Input;

function AddPurchaseOrder({
  onClose,
  visible,
  purchaseOrder,
}: IAddPurchaseOrder) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);
  const { user } = useStoreState((state) => state.account);
  const [taxes, setTaxes] = useState<{ tax: number; amount: number }[]>([]);
  const [product, setProduct] = useState<TSimpleProduct>();
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  const Currency = (val: any) => `${config_currency_symbol} ${val}`;

  const calculateTotals = useCallback(() => {
    const shipping = form.getFieldValue("shipping");
    let items = form.getFieldsValue()["purchase_items"];
    let tax = 0;
    let total = 0;

    items.forEach((item: any) => {
      tax += Number(item.totaltax);
      total += Number(item.subtotal);
    });
    let subtotal = total;
    if (shipping) {
      total += shipping * 1;
    }
    form.setFieldsValue({
      tax: Number(tax).toFixed(3),
      total: Number(total).toFixed(3),
      subtotal: Number(subtotal).toFixed(3),
    });
  }, [form]);

  const calculateTaxDistribution = useCallback(() => {
    let items = form.getFieldsValue()["purchase_items"];
    setTaxes([]);
    let updatedTaxes: { tax: number; amount: number }[] = [];
    items.forEach((item: any) => {
      if (item.tax) {
        const row = updatedTaxes.find((e) => e.tax === Number(item.tax));
        const rowIndex = updatedTaxes.findIndex(
          (e) => e.tax === Number(item.tax)
        );
        if (row) {
          updatedTaxes[rowIndex] = {
            tax: Number(row.tax),
            amount: Number(row.amount) * 1 + Number(item.totaltax),
          };
        } else {
          updatedTaxes = [
            ...updatedTaxes,
            { tax: Number(item.tax), amount: Number(item.totaltax) },
          ];
        }
      }
    });
    setTaxes(updatedTaxes);
  }, [form]);

  const calculatePurchase = useCallback(() => {
    calculateTotals();
    calculateTaxDistribution();
  }, [calculateTotals, calculateTaxDistribution]);

  useEffect(() => {
    if (purchaseOrder) {
      const {
        invoicedatetime,
        purchase_items,
        shipping,
        total,
        tax,
        refer,
        notes,
        csd,
        supplier,
      } = purchaseOrder;
      form.setFieldsValue({
        invoicedatetime: moment(invoicedatetime),
        updateStock: true,
        purchase_items,
        shipping: Number(shipping),
        total: Number(total),
        tax: Number(tax),
        supplier,
        refer,
        notes,
        csd: Number(csd),
      });
      calculatePurchase();
    } else {
      form.setFieldsValue({
        invoicedatetime: moment(),
        updateStock: true,
        purchase_items: [],
        shipping: 0,
        total: 0,
        tax: 0,
        supplier: null,
        refer: null,
        notes: "",
      });
      setTaxes([]);
    }
  }, [form, purchaseOrder, calculatePurchase]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const invoicedatetime = moment(values.invoicedatetime).format(
        DATABASE_DATE_FORMAT
      );
      let itemCount = 0;
      if (values.purchase_items) {
        itemCount = values.purchase_items.length;
      }
      let payload = {
        ...values,
        invoicedatetime,
        items: itemCount,
        eid: user?.user_id,
        discount: "0.00",
      };
      if (purchaseOrder) {
        payload = {
          ...payload,
          id: purchaseOrder.id,
          taxstatus: purchaseOrder.taxstatus,
        };
        const response = await Api.put(ENDPOINTS.POST_PURCHASE_ORDER, payload);
        if (response.data["success"] === 1) {
          onClose();
          notification.success({
            message: "Purchase order updated successfully!",
          });
        }
      } else {
        const response = await Api.post(ENDPOINTS.POST_PURCHASE_ORDER, payload);
        if (response.data["success"] === 1) {
          onClose(response.data.data);
          notification.success({
            message: "Purchase order added successfully!",
          });
        }
      }
    } catch (error) {
      console.log("error in creating supplier", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const calculateItemRow = (rowIndex: number) => {
    let items = form.getFieldsValue()["purchase_items"];
    let values = items[rowIndex];
    // calculation based on tax inclusive
    const { qty, tax, price } = values;
    const taxRate = (tax * 1 + 100) / 100;
    const cost = price / taxRate;
    const tax1 = cost * (tax / 100);
    values = {
      ...values,
      subtotal: Number((qty * price).toFixed(3)),
      totaltax: Number((qty * tax1).toFixed(3)),
    };
    items[rowIndex] = values;
    form.setFieldsValue({ purchase_items: items });
    calculatePurchase();
  };

  const onSupplierSelect = (option: {
    key: number;
    value: number;
    children: string;
  }) => {
    if (option)
      form.setFieldsValue({
        supplier: option.children,
        csd: option.value,
      });
    else
      form.setFieldsValue({
        supplier: null,
        csd: null,
      });
  };

  const onProductSelect = (product: TSimpleProduct | undefined) => {
    if (product) {
      form.setFieldsValue({
        search_product: product,
      });
      setProduct(product);
      if (product.options && !product.options.length)
        addProduct({ name: "", product_option_value_id: "" });
    } else {
      form.setFieldsValue({
        search_product: null,
      });
      setProduct(undefined);
    }
  };

  const onProductOptionSelect = (value: string) => {
    const option = product?.options[0].option_value.find(
      (item) => String(item.product_option_value_id) === value
    );
    addProduct({
      name: "- " + option?.name,
      product_option_value_id: option?.product_option_value_id,
    });
  };

  const addProduct = (optionValue: any) => {
    const product = form.getFieldsValue()["search_product"];
    if (product) {
      let items = form.getFieldsValue()["purchase_items"];
      let values = {};
      const { price, name, product_id } = product;
      // hardcoded
      const tax: number = 12;
      const taxRate = (tax * 1 + 100) / 100;
      const cost = price / taxRate;
      const tax1 = cost * (tax / 100);
      values = {
        ...values,
        pid: product_id,
        product_option_value_id: optionValue.product_option_value_id,
        product: `${name?.trim()} ${optionValue.name}`,
        qty: 1,
        tax: tax,
        price: price,
        subtotal: Number((1 * price).toFixed(3)),
        totaltax: Number((1 * tax1).toFixed(3)),
        discount: "0.00",
        totaldiscount: "0.00",
      };

      items = [...items, values];
      form.setFieldsValue({ purchase_items: items });
      calculatePurchase();
    }
  };

  return (
    <Drawer
      title="Add Purchase Order"
      placement="right"
      closable={true}
      onClose={() => {
        onClose();
      }}
      visible={visible}
      destroyOnClose
      width={isMobile ? "100%" : "60%"}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
          >
            {purchaseOrder ? "Update" : "Submit"}
          </Button>
        </div>
      }
    >
      <Form
        name="addPurchase"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        scrollToFirstError
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="d-none">
            <Form.Item label="Supplier" name="csd" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Supplier"
              name="supplier"
              rules={[{ required: true }]}
            >
              <SearchSupplier
                handleSelect={onSupplierSelect}
                supplier={
                  purchaseOrder
                    ? ({
                        id: purchaseOrder?.csd,
                        name: purchaseOrder?.supplier,
                      } as ISupplier)
                    : undefined
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Reference No."
              name="refer"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Invoice Date"
              name="invoicedatetime"
              rules={[{ required: true, type: "object" as const }]}
            >
              <DatePicker className="d-block" format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item label="Product" name="search_product">
              <SearchProduct handleSelect={onProductSelect} />
            </Form.Item>
          </Col>
          {product?.options && product.options.length ? (
            <Col xs={24} md={8}>
              <Form.Item label="Options">
                <Select
                  defaultValue={defaultSelectValue}
                  onChange={onProductOptionSelect}
                >
                  <Option value={defaultSelectValue} disabled>
                    Select Option
                  </Option>
                  {product.options[0].option_value.map((option: any) => (
                    <Option value={`${option.product_option_value_id}`}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          <Col className="py-2 mb-2 d-flex justify-content-center text-white w-100 bg-primary">
            <span>
              <b>Purchase Items</b>
            </span>
          </Col>

          <Col span="24">
            <Row gutter={[12, 12]}>
              <Form.List name="purchase_items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <PurchaseItemCard
                          key={key}
                          form={form}
                          fieldKey={fieldKey}
                          restField={restField}
                          name={name}
                          remove={remove}
                          calculatePurchase={calculatePurchase}
                          calculateItemRow={calculateItemRow}
                          purchaseOrder={purchaseOrder}
                        />
                      )
                    )}
                  </>
                )}
              </Form.List>
            </Row>
          </Col>
          <Col span="24" className="p-0 t-4">
            <Form.Item label="Notes" name="notes">
              <TextArea placeholder="Notes" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6} className="p-0">
            {taxes && taxes.length ? (
              <>
                <label>
                  <b>Tax Distribution</b>
                </label>
                <Table
                  className="pt-2"
                  bordered
                  size={"small"}
                  pagination={false}
                  dataSource={taxes}
                  footer={() => (
                    <Row>
                      <Col flex="1">Total Tax</Col>
                      <Col flex="1" className="text-end">
                        {Currency(form.getFieldValue("tax"))}
                      </Col>
                    </Row>
                  )}
                  columns={[
                    {
                      title: "Tax(%)",
                      dataIndex: "tax",
                      key: "tax",
                      render: (text) => <span>{text}%</span>,
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                      align: "right",
                      key: "tax",
                      render: (text) => <span>{Currency(text)}</span>,
                    },
                  ]}
                />
              </>
            ) : null}
          </Col>
          <Col xs={24} sm={18} className="p-0">
            <Col span="24" className="p-0">
              <Row justify="end">
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Shipping"
                    name="shipping"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <Input type="number" onChange={calculatePurchase} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span="24" className="p-0">
              <Row justify="end">
                <Col xs={24} sm={12} className="d-none">
                  <Form.Item
                    label="Tax"
                    name="tax"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="d-none">
                  <Form.Item
                    label="Grand Total"
                    name="total"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Grand Total"
                    name="total_ro"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <label>{Currency(form.getFieldValue("total"))}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span="24" className="d-none">
              <Row justify="end">
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Sub Total"
                    name="subtotal"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span="24" className="p-0">
              <Row justify="end">
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Update Stock"
                    name="updateStock"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                  >
                    <Radio.Group
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      optionType="button"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default AddPurchaseOrder;
