export enum ETransactionPayMethod {
  CASH = "CASH",
}

export enum ETransactionType {
  INCOME = "Income",
  EXPENSE = "Expense",
  TRANSFER = "Transfer",
}

export enum ETransactionCategory {
  SALES = "Sales",
  EXPENSES = "Expenses",
  PURCHASE = "Purchase",
  INCOME = "Income",
}

export interface ITransaction {
  id: string;
  acid: string;
  account: string;
  type: ETransactionType;
  cat: ETransactionCategory;
  debit: string;
  credit: string;
  payer: string;
  payerid: string;
  method: ETransactionPayMethod;
  date: string;
  tid: string;
  eid: string;
  note: string;
  ext: string;
  originaldate: string;
}
