import { ENDPOINTS } from "config/Constants";
import { Api } from "services/Api";

class ShippingServicesClass {
  getShippimgMethods = async (address_id: string | number): Promise<any> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.GET_SHIPPING}&address_id=${address_id}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching shipping methods", error);
    }
  };
}

const ShippingService = new ShippingServicesClass();
Object.freeze(ShippingService);
export default ShippingService;
