import { PlusOutlined } from "@ant-design/icons";
import { Button, TableColumnsType } from "antd";
import AddPurchaseOrder from "components/addPurchaseOrder";
import GenericTable from "components/genericTable";
import { IGenericTable, ITableDataTypes } from "interfaces/genericTable";
import { IPurchaseOrder } from "interfaces/purchaseOrder";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import PurchaseOrderStatus from "components/purchaseOrderStatus";
import { EditOutlined } from "@ant-design/icons";
import Currency from "components/currency";
import { ROUTE_SUPPLIERS } from "config/Constants";
interface IPurchaseOrderTable {
  actions: boolean;
  endpoint: string;
}

function PurchaseOrderTable({ actions, endpoint }: IPurchaseOrderTable) {
  let columns: TableColumnsType<ITableDataTypes> = [
    {
      title: "Order #",
      dataIndex: "pNumber",
      width: "10%",
      render: (name, record) => (
        <Link to={record.id} state={record} className="d-block">
          {name}
        </Link>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "company",
      width: "40%",
      render: (name, record) => (
        <Link to={`${ROUTE_SUPPLIERS}/${record.csd}`}>
          <span className="text-capitalize">{name}</span>
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "invoicedatetime",
      width: "15%",
    },
    {
      title: "Amount",
      dataIndex: "total",
      width: "15%",
      render: (name, record) => Currency(name),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (name, record) => PurchaseOrderStatus(name),
    },
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (name, record) => (
        <Button
          type="link"
          onClick={() => {
            setPurchaseOrder(record as IPurchaseOrder);
            setShowEditPurchase(true);
          }}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  // this will be inferred as `CountdownHandle`
  type GenericTableHandle = React.ElementRef<typeof GenericTable>;
  const [showAddPurchase, setShowAddPurchase] = useState(false);
  const [showEditPurchase, setShowEditPurchase] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();
  const tableRef = useRef<GenericTableHandle>(null);
  const handleTableActions = (type: string) => {
    switch (type) {
      case "add": {
        setShowAddPurchase(true);
      }
    }
  };

  const onClose = (purchaseOrder?: IPurchaseOrder) => {
    setShowAddPurchase(false);
    if (purchaseOrder) tableRef?.current?.refreshTable(true);
  };

  const onEditClose = () => {
    setShowEditPurchase(false);
    tableRef?.current?.refreshTable(true);
  };

  const [purchaseTableConfig] = useState<IGenericTable<ITableDataTypes>>({
    columns: columns,
    endpoint,
    tableActionCallback: handleTableActions,
    tableActions: actions
      ? [{ icon: <PlusOutlined />, title: "Add", type: "add" }]
      : [],
    scroll: { x: 1200 },
  });

  return (
    <div>
      <GenericTable {...purchaseTableConfig} ref={tableRef} />
      <AddPurchaseOrder visible={showAddPurchase} onClose={onClose} />
      <AddPurchaseOrder
        visible={showEditPurchase}
        onClose={onEditClose}
        purchaseOrder={purchaseOrder}
      />
    </div>
  );
}

export default PurchaseOrderTable;
