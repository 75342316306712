import {
  Col,
  Form,
  Input,
  Row,
  FormInstance,
  Card,
  Button,
  Space,
  // Select,
  Typography,
} from "antd";
import { MinusCircleOutlined, StarFilled } from "@ant-design/icons";
import { useState } from "react";
import { TSimpleProduct } from "interfaces/product";
import { useStoreState } from "hooks";
import "../styles/index.scss";
import OptionsModal from "./optionsModal";
interface IInvoiceItemCard {
  name: number;
  fieldKey: number;
  restField: any;
  remove(name: number): void;
  calculateItemRow(name: number): void;
  form: FormInstance<any>;
  calculatePurchase(): void;
}

// const { Option } = Select;
const { Paragraph } = Typography;
// const defaultSelectValue = "null";
const weightList = ["pc", "pck", undefined];

function InvoiceItemCard({
  name,
  fieldKey,
  restField,
  form,
  calculateItemRow,
  remove,
  calculatePurchase,
}: IInvoiceItemCard) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;
  const product: TSimpleProduct | undefined =
    form.getFieldValue("invoice_items")[name]["productObj"];
  const hasOptions = product?.options;

  const Currency = (val: any) => `${config_currency_symbol} ${val}`;

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchProductOptions = () => {
    setIsModalVisible(true);
  };

  const onProductOptionSelect = (value: any) => {
    setIsModalVisible(false);
    let items = form.getFieldsValue()["invoice_items"];
    items[name]["optionsStr"] = value.optionsStr;
    items[name]["options"] = value.selectedOptions;
    form.setFieldsValue({
      invoice_items: items,
    });
  };

  const getCardTitle = () => {
    if (form.getFieldValue("invoice_items")[name]["options"]) {
      const options = form.getFieldValue("invoice_items")[name]["options"];
      options.forEach((element: any) => {});
    }
    return (
      <span>
        {form.getFieldValue("invoice_items")[name]["product"]}

        {form.getFieldValue("invoice_items")[name]["optionsStr"] && (
          <Paragraph
            className="w-75 m-0"
            ellipsis
            title={form.getFieldValue("invoice_items")[name]["optionsStr"]}
          >
            <small className="text-muted">
              {form.getFieldValue("invoice_items")[name]["optionsStr"]}
            </small>
          </Paragraph>
        )}
      </span>
    );
  };

  const toggleCounter = (inc_dec: boolean) => {
    const currentValue = form.getFieldValue("invoice_items")[name]["quantity"];
    if (!inc_dec && currentValue === 1) return;

    let items = form.getFieldValue("invoice_items");
    items[name]["quantity"] = inc_dec
      ? currentValue * 1 + 1
      : currentValue * 1 - 1;

    form.setFieldsValue({ invoice_items: items });
    calculateItemRow(name);
  };

  return (
    <div key={name} className="w-100">
      <OptionsModal
        options={product?.options}
        visible={isModalVisible}
        onOk={onProductOptionSelect}
        onCancel={handleCancel}
      />

      <Col xs={24}>
        <Card
          size="small"
          className={`${(1 * name) % 2 !== 0 ? "bg-secondary" : "bg-light"}`}
          title={getCardTitle()}
          extra={
            <Space>
              {hasOptions && hasOptions.length ? (
                <Button type="primary" onClick={fetchProductOptions}>
                  Options
                </Button>
              ) : null}

              <MinusCircleOutlined
                onClick={() => {
                  remove(name);
                  calculatePurchase();
                }}
              />
            </Space>
          }
        >
          <Row>
            <Col span={24}>
              <Row gutter={12}>
                {weightList.includes(product?.weight_class) ? (
                  <Col xs={8} md={5}>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      fieldKey={[fieldKey, "quantity"]}
                      label="Quantity"
                      labelCol={{ span: 0 }}
                      rules={[{ required: true }]}
                    >
                      <Space>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          onClick={() => toggleCounter(false)}
                        >
                          -
                        </Button>
                        <label>
                          {
                            form.getFieldValue("invoice_items")[name][
                              "quantity"
                            ]
                          }
                        </label>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          onClick={() => toggleCounter(true)}
                        >
                          +
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={8} md={5}>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      fieldKey={[fieldKey, "quantity"]}
                      label="Quantity"
                      labelCol={{ span: 0 }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        type="number"
                        placeholder="Quantity"
                        min={1}
                        onChange={() => calculateItemRow(name)}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={8} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "price"]}
                    fieldKey={[fieldKey, "price"]}
                    label="Price"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      placeholder="Price"
                      onChange={() => calculateItemRow(name)}
                      // addonAfter={
                      //   product && product.special > 0 ? (
                      // <StarFilled
                      //   title="Special Price"
                      //   className="text-primary"
                      // />
                      //   ) : null
                      // }
                      addonAfter={
                        product && product.special > 0 ? (
                          <span>
                            <del className="text-primary">{product.price}</del>
                          </span>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} md={4}>
                  <Form.Item
                    {...restField}
                    name={[name, "tax"]}
                    fieldKey={[fieldKey, "tax"]}
                    label="Tax%"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      placeholder="Tax"
                      onChange={() => calculateItemRow(name)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "totaltax"]}
                    fieldKey={[fieldKey, "totaltax"]}
                    label="Tax"
                    labelCol={{ xs: { span: 6 }, md: { span: 0 } }}
                    labelAlign="left"
                  >
                    <label>
                      {Currency(
                        form.getFieldValue("invoice_items")[name]["totaltax"]
                      )}
                    </label>
                  </Form.Item>
                </Col>
                <Col xs={12} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "subtotal"]}
                    fieldKey={[fieldKey, "subtotal"]}
                    label="Amount"
                    labelCol={{ xs: { span: 6 }, md: { span: 0 } }}
                    labelAlign="left"
                  >
                    <label>
                      {Currency(
                        form.getFieldValue("invoice_items")[name]["subtotal"]
                      )}
                    </label>
                  </Form.Item>
                </Col>
                <Col flex="1" className="d-none">
                  <Form.Item
                    {...restField}
                    name={[name, "product_id"]}
                    fieldKey={[fieldKey, "product_id"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "discount"]}
                    fieldKey={[fieldKey, "discount"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "totaldiscount"]}
                    fieldKey={[fieldKey, "totaldiscount"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "options"]}
                    fieldKey={[fieldKey, "options"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "optionsStr"]}
                    fieldKey={[fieldKey, "optionsStr"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "productObj"]}
                    fieldKey={[fieldKey, "productObj"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
}

export default InvoiceItemCard;
