import { ITransaction } from "interfaces/transaction";
import React, { useState } from "react";
import TransactionsTable from "./components/transactionsTable";
import AddTransactions from "./components/addTransactions";
import { ENDPOINTS } from "config/Constants";
import PageHeading from "components/headings/headings";

function Transactions() {
  const [showAddTransaction, setShowAddTransaction] = useState(false);

  const onClose = (transaction?: ITransaction) => {
    setShowAddTransaction(false);
  };
  return (
    <div>
      <PageHeading label="Transactions"></PageHeading>
      <TransactionsTable actions={true} endpoint={ENDPOINTS.GET_TRANSACTIONS} />
      <AddTransactions visible={showAddTransaction} onClose={onClose} />
    </div>
  );
}

export default Transactions;
