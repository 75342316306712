import { Carousel } from "antd";
import React, { useEffect, useState } from "react";
import IMenuProduct from "../interface/product";
import TvMenuService from "../services";
import ProductsGrid from "./productsGrid";

function CategoryCaraousel() {
  const [chickenProducts, setChickenProducts] = useState<IMenuProduct[]>([]);
  const [muttonProducts, setMuttonProducts] = useState<IMenuProduct[]>([]);
  const [seafoodProducts, setSeafoodProducts] = useState<IMenuProduct[]>([]);
  useEffect(() => {
    oninit();
  }, []);

  const oninit = async () => {
    const response = await TvMenuService.getProductsByCategory(255);
    setChickenProducts(response);
    const muttonproducts = await TvMenuService.getProductsByCategory(257);
    setMuttonProducts(muttonproducts);
    const seaproducts = await TvMenuService.getProductsByCategory(145);
    setSeafoodProducts(seaproducts);
  };

  return (
    <Carousel
      autoplay
      effect="fade"
      dots={false}
      pauseOnHover={false}
      autoplaySpeed={6000}
    >
      <div className="productsGrid">
        <ProductsGrid products={chickenProducts} category="chicken" />
      </div>
      <div className="productsGrid">
        <ProductsGrid products={muttonProducts} category="mutton" />
      </div>
      <div className="productsGrid">
        <ProductsGrid products={seafoodProducts} category="seafood" />
      </div>
    </Carousel>
  );
}

export default CategoryCaraousel;
