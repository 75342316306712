import React from "react";
import SupplierStore from "./store";
import SuppliersView from "./suppliersView";

function Suppliers() {
  return (
    <SupplierStore.Provider>
      <SuppliersView />
    </SupplierStore.Provider>
  );
}

export default Suppliers;
