import {
  CopyOutlined,
  DashboardOutlined,
  UserOutlined,
  MenuOutlined,
  AppstoreOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { IRoute } from "interfaces/route";
import Accounts from "pages/accounts/accounts";
import Dashboard from "pages/dashboard/dashboard";
import Login from "pages/login/login";
import NotFound from "pages/notfound/notfound";
import PrivateLayout from "pages/privateLayout/privateLayout";
import PurchaseOrders from "pages/purchaseOrders";
import Suppliers from "pages/suppliers";
import SupplierView from "pages/suppliers/supplier/supplierView";
import TvMenu from "pages/tvmenu";
import { Navigate } from "react-router-dom";
import { IUserinfo, Usergroup } from "store/model/account";
import Transactions from "pages/transactions";
import Menu from "pages/menu";
import PurchaseOrderView from "pages/purchaseOrders/purchaseOrderView/purchaseOrderView";
import POS from "pages/pos";

export const getPrivateRoutes = (
  userGruop: Usergroup | undefined
): IRoute[] => {
  switch (userGruop) {
    case Usergroup.Administrator: {
      return [
        {
          path: "/dashboard",
          element: <Dashboard />,
          title: "Dashboard",
          icon: <DashboardOutlined />,
        },
        {
          path: "/suppliers",
          element: <Suppliers />,
          title: "Suppliers",
          icon: <UserOutlined />,
          children: [
            {
              path: ":id",
              element: <SupplierView />,
              title: "Supplier",
              icon: <DashboardOutlined />,
            },
          ],
        },
        {
          path: "/purchase-orders",
          element: <PurchaseOrders />,
          title: "Purchase Orders",
          icon: <CopyOutlined />,
          children: [
            {
              path: ":id",
              element: <PurchaseOrderView />,
              title: "Supplier",
              icon: <DashboardOutlined />,
            },
          ],
        },
        {
          path: "/accounts",
          element: <Accounts />,
          title: "Accounts",
          icon: <WalletOutlined />,
        },
        {
          path: "/transactions",
          element: <Transactions />,
          title: "Transactions",
          icon: <MenuOutlined />,
        },
        {
          path: "/menu",
          element: <Menu />,
          title: "Menu",
          icon: <AppstoreOutlined />,
        },
        {
          path: "/pos",
          element: <POS />,
          title: "POS",
          icon: <AppstoreOutlined />,
          hideFromMenu: true,
        },
        {
          path: "/",
          title: "",
          icon: <></>,
          element: <Navigate to="/dashboard" />,
          hideFromMenu: true,
        },
      ];
    }
    case Usergroup.tvmenu: {
      return [
        {
          path: "/",
          element: <TvMenu />,
          title: "Dashboard",
          icon: <DashboardOutlined />,
        },
      ];
    }
  }
  return [];
};

const getLayout = (userGroup: Usergroup | undefined) => {
  switch (userGroup) {
    case Usergroup.Administrator:
      return <PrivateLayout />;
    case Usergroup.tvmenu:
      return <TvMenu />;
    default:
      return <NotFound />;
  }
};

const routes = (isLoggedIn: boolean, user?: IUserinfo) => [
  {
    path: "/",
    element: isLoggedIn ? (
      getLayout(user?.user_group)
    ) : (
      <Navigate to="/login" />
    ),
    children: getPrivateRoutes(user?.user_group),
  },
  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
  },
  {
    path: "*",
    element: isLoggedIn ? <NotFound /> : <Navigate to="/login" />,
  },
];

export default routes;
