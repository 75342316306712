import { Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import TvMenuService from "../services";

function CheckNewOrder() {
  const [audio] = useState(
    new Audio(
      "https://soundbible.com/mp3/Store_Door_Chime-Mike_Koenig-570742973.mp3"
    )
  );
  const [playing, setPlaying] = useState(false);
  const [lastOrderId, setLastOrderId] = useState(0);

  useEffect(() => {
    Modal.error({
      title: "Click OK to Start",
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggle = useCallback(() => setPlaying(!playing), []);
  useEffect(() => {
    playing ? playAlert() : audio.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, audio]);

  const playAlert = () => {
    audio.play();
    setTimeout(() => {
      audio.play();
    }, 2500);
  };

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(async () => {
      const recentid = await TvMenuService.getLastOrder();
      console.log("lastid ", recentid);
      if (recentid > lastOrderId) {
        toggle();
      }
      setLastOrderId((lastOrderId) => recentid);
    }, 120000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOrderId]);

  return <></>;
}

export default CheckNewOrder;
