import { Action, action } from "easy-peasy";

export enum Usergroup {
  Administrator = "Administrator",
  DeliveryAgent = "DeliveryAgent",
  tvmenu = "tvmenu",
}

export interface IUserinfo {
  user_id: number;
  user_group_id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  image: string;
  ip: string;
  status: 1;
  date_added: string;
  user_group: Usergroup;
}

export interface IAccount {
  isLoggedIn: boolean;
  user: IUserinfo | undefined;
  setUser: Action<IAccount, IUserinfo>;
}

const account: IAccount = {
  isLoggedIn: false,
  user: undefined,
  setUser: action((state, payload) => {
    state.user = payload;
    state.isLoggedIn = payload != null ? true : false;
  }),
};

export default account;
