import { Col, Row, List, Switch, Button, message } from "antd";
import { ICategory } from "interfaces/Category";
import { IAdminProduct } from "interfaces/product";
import { useState, useEffect } from "react";
import CategoryService from "services/categoryServices";
import ProductServices from "services/productServices";
import "../styles/index.scss";

function CategoryMenu() {
  const [loading, setLoading] = useState(false);
  const [loadingSubCategory, setLoadingSubCategory] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [parentCategories, setParentCategories] = useState<ICategory[]>([]);
  const [currentParent, setCurrentParent] = useState<number | undefined>();
  const [currentSubCat, setCurrentSubCat] = useState<number | undefined>();
  const [subCategories, setSubCategories] = useState<ICategory[]>([]);
  const [products, setProducts] = useState<IAdminProduct[]>([]);

  useEffect(() => {
    getParentCategories();
  }, []);

  const getParentCategories = async () => {
    setLoading(true);

    const categories = await CategoryService.getParentCategories();
    setParentCategories(categories ?? []);
    setLoading(false);
  };

  const getSubCategories = async (parentId: number) => {
    setLoadingSubCategory(true);
    setCurrentParent(parentId);
    setCurrentSubCat(undefined);
    const categories = await CategoryService.getCategoryByParentId(parentId);
    setSubCategories(categories ?? []);
    setLoadingSubCategory(false);
    getProducts(parentId);
  };

  const getSubCatProducts = (subCatId: number) => {
    setCurrentSubCat(subCatId);
    getProducts(subCatId);
  };

  const getProducts = async (catId: number) => {
    setLoadingProducts(true);
    const products = await ProductServices.fetchProductByCategory(catId);
    setProducts(products ?? []);
    setLoadingProducts(false);
  };

  const onChange = async (
    checked: any,
    e: any,
    id: number,
    refreshData: boolean
  ) => {
    e.stopPropagation();
    let status = 1;
    if (checked) status = 1;
    else status = 0;
    await CategoryService.updateCategory(status, id);
    if (refreshData) {
      getParentCategories();
      getSubCategories(id);
    } else {
      getSubCategories(currentParent ?? -1);
    }
  };

  const updateQty = async (e: any, id: number) => {
    e.stopPropagation();
    await CategoryService.makeZeroQty(id);
    getProducts(id);
    message.success("Qty Updated!");
  };

  const updateProductQty = async (e: any, id: number) => {
    e.stopPropagation();
    await ProductServices.updateProduct(id, {
      quantity: 0,
    });

    if (currentSubCat) {
      getProducts(currentSubCat);
    } else {
      getProducts(currentParent ?? -1);
    }
    message.success("Qty Updated!");
  };

  const updateProductStatus = async (
    checked: any,
    e: any,
    id: number,
    refreshData: boolean
  ) => {
    e.stopPropagation();
    let status = 1;
    if (checked) status = 1;
    else status = 0;
    await ProductServices.updateProduct(id, {
      status,
    });
    if (currentSubCat) {
      getProducts(currentSubCat);
    } else {
      getProducts(currentParent ?? -1);
    }
  };

  const StatusSwitch = ({
    status,
    onChange,
  }: {
    status: string | number;
    onChange: any;
  }) => (
    <Switch
      checked={status === 1 || status === "1" ? true : false}
      onChange={onChange}
    />
  );

  const QuantityButton = ({ onClick }: { onClick: any }) => (
    <Button type="dashed" size="small" onClick={onClick}>
      Zero Qty
    </Button>
  );

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} lg={8}>
        <List
          bordered
          header={<h3>Categories | {parentCategories.length}</h3>}
          itemLayout="horizontal"
          loading={loading}
          dataSource={parentCategories}
          renderItem={(item) => (
            <List.Item
              className={`cursor-pointer ${
                currentParent === item.category_id && "bg-secondary"
              }`}
              actions={[
                <StatusSwitch
                  status={item.status}
                  onChange={(e: any, t: any) =>
                    onChange(e, t, item.category_id, true)
                  }
                />,
                <QuantityButton
                  onClick={(e: any) => updateQty(e, item.category_id)}
                />,
              ]}
              onClick={() => getSubCategories(item.category_id)}
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      </Col>

      <Col xs={24} lg={8}>
        <List
          bordered
          header={<h3>Sub Categories | {subCategories.length}</h3>}
          itemLayout="horizontal"
          loading={loadingSubCategory}
          dataSource={subCategories}
          renderItem={(item) => (
            <List.Item
              className={`cursor-pointer ${
                currentSubCat === item.category_id && "bg-secondary"
              }`}
              actions={[
                <StatusSwitch
                  status={item.status}
                  onChange={(e: any, t: any) =>
                    onChange(e, t, item.category_id, false)
                  }
                />,
                <QuantityButton
                  onClick={(e: any) => updateQty(e, item.category_id)}
                />,
              ]}
              onClick={() => getSubCatProducts(item.category_id)}
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      </Col>

      <Col xs={24} lg={8}>
        <List
          bordered
          header={<h3>Products | {products.length}</h3>}
          itemLayout="horizontal"
          loading={loadingProducts}
          dataSource={products}
          renderItem={(item) => (
            <List.Item
              actions={[
                <StatusSwitch
                  status={item.status}
                  onChange={(e: any, t: any) =>
                    updateProductStatus(e, t, item.id, false)
                  }
                />,
                <QuantityButton
                  onClick={(e: any) => updateProductQty(e, item.id)}
                />,
              ]}
            >
              <List.Item.Meta
                title={`${item.product_description[0].name} (${item.quantity})`}
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
}

export default CategoryMenu;
