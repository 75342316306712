import { ENDPOINTS } from "config/Constants";
import { ICategory } from "interfaces/Category";
import { Api } from "services/Api";

class CategoryServiceClass {
  getParentCategories = async (): Promise<ICategory[] | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.GET_CATEGORY_BY_LEVEL}/1`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };

  getCategoryByParentId = async (
    parentId: number
  ): Promise<ICategory[] | undefined> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.GET_CATEGORY_BY_PARENT_ID}/${parentId}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };

  updateCategory = async (status: number, id: number): Promise<any> => {
    try {
      const response = await Api.post<any>(
        `${ENDPOINTS.ENABLE_DISABLE_CATEGORY}`,
        {
          enable: status,
          categoryid: id,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };

  makeZeroQty = async (id: number): Promise<any> => {
    try {
      const response = await Api.post<any>(
        `${ENDPOINTS.MAKE_ZERO_QTY}`,
        {
          categoryid: id,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };
}

const CategoryService = new CategoryServiceClass();
Object.freeze(CategoryService);
export default CategoryService;
