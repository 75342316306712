import {
  Modal,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Button,
  Divider,
  InputNumber,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { IShipping } from "interfaces/Shipping";
import { ICustomer } from "interfaces/Customer";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

interface ICashPaymentModal {
  total: number;
  onOk(value: any): void;
  visible: boolean;
  onCancel(): void;
  handleShippingChange(value: any): void;
  handleAddressChange(value: any): void;
  shippingMethods: any;
  customer?: ICustomer;
  showModal(): void;
  resetShipping(): void;
}

function CashPaymentModal({
  total,
  visible,
  onCancel,
  onOk,
  handleShippingChange,
  handleAddressChange,
  shippingMethods,
  customer,
  showModal,
  resetShipping,
}: ICashPaymentModal) {
  const inputRef = React.useRef<any>(null);
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const [changeAmount, setChangeAmount] = useState<number>(0);

  useEffect(() => {
    inputRef.current!.focus({
      cursor: "start",
    });
  });

  const ShippingMethods = () => (
    <Col span={24}>
      <Row wrap={false}>
        <Col flex="auto">
          <Form.Item
            name="shipping_method"
            className="m-0 mb-2"
            rules={[{ required: showAddress }]}
            label="Shipping Method"
            labelCol={{ span: 0 }}
          >
            <Select
              className="d-block"
              placeholder="Select Shipping"
              onChange={handleShippingChange}
            >
              {Object.keys(shippingMethods).map((item) => {
                const method: IShipping = shippingMethods[item];
                return (
                  <Option value={item} key={method.code}>
                    {method.title} - {method.text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );

  const CustomerAddress = () => (
    <Col span={24}>
      <Row wrap={false}>
        <Col flex="auto">
          <Form.Item
            name="address_id"
            className="m-0 mb-2"
            rules={[{ required: showAddress }]}
            label="Address"
            labelCol={{ span: 0 }}
          >
            <Select
              className="d-block"
              placeholder="Select address"
              onChange={handleAddressChange}
            >
              {customer?.addresses && customer?.addresses.length
                ? customer.addresses.map((item) => (
                    <Option value={item.address_id} key={item.address_id}>
                      {item.address_1} {item.address_2}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal} />
        </Col>
      </Row>
    </Col>
  );

  const handleDeliveryChange = (e: any) => {
    if (!e.target.checked) {
      resetShipping();
    }
    setShowAddress(e.target.checked);
  };

  const onCashChange = (value: any) => {
    if (value) {
      const leftOverAmount = value * 1 - total * 1;
      setChangeAmount(leftOverAmount);
    } else {
      setChangeAmount(0);
    }
  };

  return (
    <Modal
      title="Payment"
      visible={visible}
      onOk={onOk}
      okText="Pay"
      onCancel={onCancel}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="isDeliveryNeeded"
            valuePropName="checked"
            className="m-0"
          >
            <Checkbox onChange={handleDeliveryChange}>Needs delivery</Checkbox>
          </Form.Item>
        </Col>
        {showAddress ? (
          <>
            <CustomerAddress />
            {shippingMethods ? <ShippingMethods /> : null}
          </>
        ) : null}

        <Col span={24}>
          <Divider />
          <Space className="d-block text-center text-primary">
            <h1 className="text-center text-primary m-0">{total}</h1>
            <h2 className="text-center text-danger">{changeAmount}</h2>
          </Space>
        </Col>
        <Col span={24}>
          <InputNumber
            ref={inputRef}
            className="w-100"
            min={0}
            onChange={onCashChange}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default CashPaymentModal;
