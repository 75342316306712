import { Button, Card, Col, Form, Input, message, Row, Space } from "antd";
import { ENDPOINTS } from "config/Constants";
import { useStoreActions, useStoreState } from "hooks";
import React from "react";
import { Navigate } from "react-router";
import { Api } from "services/Api";
import "./login.css";

function Login() {
  const isLoggedIn = useStoreState((state) => state.account.isLoggedIn);
  const setUser = useStoreActions((actions) => actions.account.setUser);

  const onFinish = async (values: any) => {
    try {
      const response = await Api.post(ENDPOINTS.LOGIN, values);
      if (response.data["success"] === 1) {
        setUser(response.data["data"]);
      }
    } catch (error) {
      message.error(error.data["error"][0]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <div>
      <Row justify="center" align="middle" className="login">
        <Col xs={24} sm={16} md={8} lg={6}>
          <Card>
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Space direction="vertical">
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Submit
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
