import { ENDPOINTS } from "config/Constants";
import { IAccount } from "interfaces/account";
import { Api } from "services/Api";

class AccountServiceClass {
  getAccounts = async (): Promise<IAccount[] | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.GET_ACCOUNTS}`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching suppliers", error);
    }
  };
}

const AccountService = new AccountServiceClass();
Object.freeze(AccountService);
export default AccountService;
