import { Descriptions, Drawer, Space, Tabs } from "antd";
import Loading from "components/loading";
import { useStoreState } from "hooks";
import { ISupplier } from "interfaces/suppliers";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import SuppliersService from "../services/suppliersService";
import PurchaseOrderTable from "components/purchaseOrderTable";
import { ENDPOINTS } from "config/Constants";
import TransactionsTable from "pages/transactions/components/transactionsTable";

const { TabPane } = Tabs;

function SupplierView() {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const [supplier, setSupplier] = useState<ISupplier>();
  const [loading, setLoading] = useState(true);
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);

  useEffect(() => {
    if (!location.state) {
      getSupplierData();
    } else {
      setSupplier(location.state as ISupplier);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupplierData = async () => {
    let data = await SuppliersService.fetchSupplier(params.id);
    setSupplier(data);
    setLoading(false);
  };

  return (
    <Drawer
      title={<span className="text-capitalize">{supplier?.company}</span>}
      placement="right"
      closable={true}
      onClose={() => {
        navigate("/suppliers");
      }}
      visible={true}
      destroyOnClose
      width={isMobile ? "100%" : "60%"}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Space direction="vertical">
            <Descriptions bordered>
              <Descriptions.Item label="Contact Person">
                {supplier?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Contact No.">
                {supplier?.phone}
              </Descriptions.Item>
              {supplier?.address ? (
                <Descriptions.Item label="Address">
                  {supplier?.address}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Purchase Orders" key="1">
                <PurchaseOrderTable
                  actions={false}
                  endpoint={`${ENDPOINTS.GET_PURCHASE_ORDERS}&filter_supplier=${supplier?.id}`}
                />
              </TabPane>
              <TabPane tab="Transactions" key="2">
                <TransactionsTable
                  actions={false}
                  endpoint={`${ENDPOINTS.GET_TRANSACTIONS}&payerid=${supplier?.id}`}
                />
              </TabPane>
            </Tabs>
          </Space>
        </>
      )}
    </Drawer>
  );
}

export default SupplierView;
