import { ITransaction } from "./transaction";

export interface IPurchaseItem {
  id: string;
  tid: string;
  pid: string;
  product: string;
  qty: string;
  price: string;
  tax: string;
  discount: string;
  subtotal: string;
  totaltax: string;
  totaldiscount: string;
}
export interface IPurchaseOrder {
  id: string;
  invoicedatetime: string;
  subtotal: string;
  shipping: string;
  discount: string;
  tax: string;
  total: string;
  notes: string;
  status: EPurchaseOrderStatus;
  csd: string;
  eid: string;
  items: string;
  taxstatus: string;
  refer: string;
  company: string;
  supplier: string;
  purchase_items: IPurchaseItem[];
  TotalPaymentDone: number;
  PaymentDue: number;
  transactions: ITransaction[];
  originalinvoicedatetime: string;
  pNumber: string;
}

export enum EPurchaseOrderStatus {
  DUE = "due",
  PARTIAL = "partial",
  PAID = "paid",
  CANCELED = "canceled",
}
