import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import SuppliersService from "pages/suppliers/services/suppliersService";
import { IStats, EStatsType } from "interfaces/suppliers";
import { useStoreState } from "hooks";

const renderValue = (
  symbol: string,
  type: string | undefined,
  value: string
) => {
  switch (type) {
    case EStatsType.AMOUNT:
      return `${symbol} ${value}`;

    default:
      return value;
  }
};

function Statistics() {
  const [stats, setStats] = useState<IStats | undefined>();
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    const stats: IStats | undefined = await SuppliersService.getStats();
    setStats(stats);
  };
  return (
    <Row gutter={16}>
      {stats
        ? (Object.keys(stats) as Array<keyof IStats>).map((item) => {
            const statistic = stats[item];
            return (
              <Col flex={1} className="py-1 py-md-0">
                <Card>
                  <Statistic
                    title={
                      <span className="fw-bold fs-6">{statistic.title}</span>
                    }
                    value={renderValue(
                      config_currency_symbol,
                      statistic.type,
                      statistic.value
                    )}
                    // precision={2}
                    valueStyle={{ color: "#3f8600" }}
                    // suffix="%"
                  />
                </Card>
              </Col>
            );
          })
        : null}
    </Row>
  );
}

export default Statistics;
