import { PlusOutlined } from "@ant-design/icons";
import { TableColumnsType } from "antd";
import AddSupplier from "components/addSupplier";
import GenericTable from "components/genericTable";
import { ENDPOINTS } from "config/Constants";
import { IGenericTable, ITableDataTypes } from "interfaces/genericTable";
import { ISupplier } from "interfaces/suppliers";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

const columns: TableColumnsType<ITableDataTypes> = [
  {
    title: "Supplier",
    dataIndex: "company",
    render: (name, record) => (
      <Link to={record.id} state={record}>
        {name}
      </Link>
    ),
  },
  {
    title: "Contact Person",
    dataIndex: "name",
  },
  {
    title: "Contact Number",
    dataIndex: "phone",
  },
];

function SuppliersTable() {
  // this will be inferred as `CountdownHandle`
  type GenericTableHandle = React.ElementRef<typeof GenericTable>;
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const tableRef = useRef<GenericTableHandle>(null);
  const handleTableActions = (type: string) => {
    switch (type) {
      case "add": {
        setShowAddSupplier(true);
      }
    }
  };

  const onClose = (supplier?: ISupplier) => {
    setShowAddSupplier(false);
    if (supplier) tableRef?.current?.refreshTable(true);
  };

  const [suppliersTableConfig] = useState<IGenericTable<ITableDataTypes>>({
    columns: columns,
    endpoint: ENDPOINTS.GET_SUPPLIERS,
    tableActionCallback: handleTableActions,
    tableActions: [{ icon: <PlusOutlined />, title: "Add", type: "add" }],
    scroll: { x: 900 },
  });

  return (
    <div>
      <GenericTable {...suppliersTableConfig} ref={tableRef} />
      <AddSupplier visible={showAddSupplier} onClose={onClose} />
    </div>
  );
}

export default SuppliersTable;
