import { PlusOutlined } from "@ant-design/icons";
import { TableColumnsType } from "antd";
import GenericTable from "components/genericTable";
import { ROUTE_CUSTOMERS, ROUTE_SUPPLIERS } from "config/Constants";
import {
  ETransactionCategory,
  ETransactionType,
  ITransaction,
} from "interfaces/transaction";
import { IGenericTable, ITableDataTypes } from "interfaces/genericTable";
import React, { useRef, useState } from "react";
import AddTransaction from "./addTransactions";
import { Link } from "react-router-dom";
import Currency from "components/currency";

interface ITransactionsTable {
  actions: boolean;
  endpoint: string;
}

const columns: TableColumnsType<ITableDataTypes> = [
  {
    title: "Payer",
    dataIndex: "payer",
    width: "20%",
    render: (name, record) => redirectTo(name, record),
  },
  {
    title: "Account",
    dataIndex: "account",
    width: "15%",
    render: (name) => <span className="text-capitalize">{name}</span>,
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "10%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "10%",
    render: (name, record) =>
      record.type === ETransactionType.EXPENSE ? (
        <span className="text-danger">{Currency(record.debit)}</span>
      ) : (
        <span className="text-success">{Currency(record.credit)}</span>
      ),
  },
  {
    title: "Date",
    dataIndex: "date",
    width: "20%",
  },
  {
    title: "Notes",
    dataIndex: "note",
    width: "25%",
  },
];

function redirectTo(name: string, record: ITableDataTypes) {
  switch (record.cat) {
    case ETransactionCategory.PURCHASE:
      return (
        <Link to={`${ROUTE_SUPPLIERS}/${record.payerid}`} state={record}>
          {name}
        </Link>
      );
    case ETransactionCategory.SALES:
      return (
        <Link to={`${ROUTE_CUSTOMERS}/${record.payerid}`} state={record}>
          {name}
        </Link>
      );

    default:
      return name || "N/A";
  }
}

function TransactionsTable({ actions, endpoint }: ITransactionsTable) {
  type GenericTableHandle = React.ElementRef<typeof GenericTable>;
  const tableRef = useRef<GenericTableHandle>(null);
  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const handleTableActions = (type: string) => {
    switch (type) {
      case "add": {
        setShowAddTransaction(true);
      }
    }
  };

  const onClose = (transaction?: ITransaction) => {
    setShowAddTransaction(false);
    if (transaction) tableRef?.current?.refreshTable(true);
  };

  const [transactionsTableConfig] = useState<IGenericTable<ITableDataTypes>>({
    columns: columns,
    endpoint,
    tableActionCallback: handleTableActions,
    tableActions: actions
      ? [{ icon: <PlusOutlined />, title: "Add", type: "add" }]
      : [],
    enablePagination: false,
    scroll: { x: 1200 },
  });

  return (
    <div>
      <GenericTable {...transactionsTableConfig} ref={tableRef} />
      <AddTransaction visible={showAddTransaction} onClose={onClose} />
    </div>
  );
}

export default TransactionsTable;
