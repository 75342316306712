import { createContextStore } from "easy-peasy";
// import { createContextStore, action, Action, Thunk, thunk } from "easy-peasy";
// import { ISupplier } from "interfaces/suppliers";
// import SuppliersService from "../services/suppliersService";

interface ISupplierStore {}

const SupplierStore = createContextStore<ISupplierStore>({
  /* suppliers: [],
  tableState: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
    searchTerm: "",
    loading: false,
    total: 0,
  },
  setSuppliers: action((state, payload) => {
    state.suppliers = payload;
  }),
  setTableState: action((state, payload) => {
    state.tableState = payload;
  }),
  fetchSuppliers: thunk(async (actions, payload: ITableState) => {
    actions.setTableState({ ...payload, loading: true });
    const response = await SuppliersService.fetchSuppliers(
      payload.pagination.pageSize,
      payload.pagination.current,
      payload.searchTerm
    );
    console.log("x-total-count", response?.headers["x-total-count"]);
    if (response?.data) {
      actions.setSuppliers(response.data["data"]);
      actions.setTableState({
        ...payload,
        loading: false,
        total: response?.headers["x-total-count"],
      });
    }
  }), */
});

export default SupplierStore;
