import React from "react";
interface IHeading {
  label: string;
}
function PageHeading({ label }: IHeading) {
  return (
    <div>
      <h2>{label}</h2>
    </div>
  );
}

export default PageHeading;
