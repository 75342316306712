import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import { ENDPOINTS } from "config/Constants";
import { useStoreState } from "hooks";
import { IAccount, EAccountType } from "interfaces/account";
import React, { useState } from "react";
import { Api } from "services/Api";

interface IAddAccount {
  visible: boolean;
  onClose: (account?: IAccount) => void;
}

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  /* types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  }, */
};
/* eslint-enable no-template-curly-in-string */

const AccountTypes = [
  EAccountType.ASSETS,
  EAccountType.BASIC,
  EAccountType.EQUITY,
  EAccountType.EXPENSES,
  EAccountType.INCOME,
  EAccountType.LIABILITIES,
];

const defaultSelectValue = "null";

function AddAccount({ onClose, visible }: IAddAccount) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await Api.post(ENDPOINTS.POST_ACCOUNTS, values);
      if (response.data["success"] === 1) {
        onClose(response.data.data);
        notification.success({ message: "Account added successfully!" });
      }
    } catch (error) {
      console.log("error in creating account", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Drawer
      title="Add Account"
      placement="right"
      closable={true}
      onClose={() => {
        onClose();
      }}
      visible={visible}
      destroyOnClose
      width={isMobile ? "100%" : "320px"}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
          >
            Submit
          </Button>
        </div>
      }
    >
      <Form
        name="addPurchase"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        scrollToFirstError
        form={form}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item
              label="Account Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Account No."
              name="accountnumber"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="IFSC Code"
              name="code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Account Type"
              name="accounttype"
              rules={[{ required: true }]}
            >
              <Select defaultValue={defaultSelectValue}>
                <Option value={defaultSelectValue} disabled>
                  Select Type
                </Option>
                {AccountTypes.map((type) => (
                  <Option value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default AddAccount;
