import { ENDPOINTS } from "config/Constants";
import { ICountry } from "interfaces/localisation";
import { Api } from "services/Api";

class LocalisationServicesClass {
  getCountries = async (): Promise<ICountry[] | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.COUNTRY}`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };
  getZonesByCountry = async (
    countryId: string | number
  ): Promise<ICountry | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.COUNTRY}/${countryId}`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };
}

const LocalisationService = new LocalisationServicesClass();
Object.freeze(LocalisationService);
export default LocalisationService;
