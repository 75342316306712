import { Api } from "services/Api";
import IMenuProduct from "../interface/product";

class TvMenuService {
  static getProductsByCategory = async (
    id: number
  ): Promise<IMenuProduct[]> => {
    const response = await Api.get(`/api/rest/products/category/${id}`);
    return response.data["data"];
  };

  static getLastOrder = async (): Promise<number> => {
    const response = await Api.get(
      `/index.php?route=rest/order_admin/orders&limit=1&page=1&order=DESC`
    );
    return response.data["data"][0]["order_id"];
  };
}

export default TvMenuService;
