import { Select } from "antd";
import { ICustomer } from "interfaces/Customer";
import { debounce } from "lodash-es";
import customersService from "services/customerServices";
import { useState } from "react";

const { Option } = Select;

interface ISearchCustomer {
  handleSelect: (option: ICustomer | undefined) => void;
}

function SearchCustomer({ handleSelect }: ISearchCustomer) {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = debounce(async (value: string) => {
    if (value) {
      const customers = await customersService.searchCustomer(value);
      setCustomers(customers ?? []);
    } else {
      setCustomers([]);
    }
  }, 400);

  const handleChange = (value: string, option: any) => {
    setValue(value);
    const customer = customers?.find(
      (item) => item.customer_id === option?.value
    );
    handleSelect(customer);
  };

  const options = customers.map((customer) => (
    <Option
      key={customer.customer_id}
      value={customer.customer_id}
      disabled={customer.status ? false : true}
    >
      {customer.firstname} {customer.lastname}{" "}
      {customer?.telephone ? "(" + customer?.telephone + ")" : null}
    </Option>
  ));

  return (
    // <Row>
    //   <Col flex="1">
    <Select
      allowClear
      showSearch
      style={{ minWidth: "200px" }}
      value={value}
      placeholder={"Search customer"}
      defaultActiveFirstOption={false}
      filterOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={<h4>no customer found</h4>}
    >
      {options}
    </Select>
    /* </Col>
      <Col>
        <Button type="primary" icon={<PlusOutlined />} />
      </Col>
    </Row> */
  );
}

export default SearchCustomer;
