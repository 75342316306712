import { Drawer, Space, Table, Divider, Button } from "antd";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { useStoreState } from "../../../hooks";
import { IPurchaseOrder } from "../../../interfaces/purchaseOrder";
import { ISupplier } from "../../../interfaces/suppliers";
import { useLocation, useNavigate, useParams } from "react-router";
import PurchaseOrderService from "../../../services/purchaseOrderServices";
import SuppliersService from "../../../pages/suppliers/services/suppliersService";
import {
  ROUTE_PURCHASE_ORDERS,
  ROUTE_SUPPLIERS,
} from "../../../config/Constants";
import "./style.scss";
import PurchaseOrderStatus from "components/purchaseOrderStatus";
import { ETransactionType } from "interfaces/transaction";
import { Link } from "react-router-dom";

function PurchaseOrderView() {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();
  const [supplier, setSupplier] = useState<ISupplier>();
  const [printMode, setPrintMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  useEffect(() => {
    if (!location.state) {
      getPurchaseOrder();
    } else {
      const purchaseOrderData = location.state as IPurchaseOrder;
      setPurchaseOrder(purchaseOrderData);
      if (purchaseOrderData?.csd) getSupplierData(purchaseOrderData?.csd);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPurchaseOrder = async () => {
    let data = await PurchaseOrderService.fetchPurchaseOrder(params.id);
    setPurchaseOrder(data);
    if (data?.csd) getSupplierData(data?.csd);
    setLoading(false);
  };

  const getSupplierData = async (supplierId: string) => {
    let data = await SuppliersService.fetchSupplier(supplierId);
    setSupplier(data);
    setLoading(false);
  };

  const Currency = (value: number | string | undefined) =>
    `${config_currency_symbol} ${value}`;

  const purchaseItemscolumns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "1",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "2",
      render: (name: any) => Currency(name),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "3",
    },
    {
      title: "tax",
      dataIndex: "tax",
      key: "4",
      render: (name: any) => Currency(name),
    },
    {
      title: "Sub Total",
      dataIndex: "subtotal",
      key: "5",
      render: (name: any) => Currency(name),
    },
  ];

  const supplierColumns = [
    {
      title: "Our Info",
      dataIndex: "supplierName",
      key: "1",
      render: (text: any, row: any, index: number) => (
        <div>
          <p>{storeInfo?.config_name}</p>
          <p>{storeInfo?.config_telephone}</p>
          <p>{storeInfo?.config_email}</p>
          <p>{storeInfo?.config_address}</p>
        </div>
      ),
      width: "50%",
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "2",
      width: "50%",
      className: "d-flex align-items-start flex-column",
      render: (text: any, row: any, index: number) => (
        <div className="supplier-info">
          <Link to={`${ROUTE_SUPPLIERS}/${supplier?.id}`}>
            <p className="text-capitalize">{supplier?.company}</p>
          </Link>
          <p>{supplier?.phone}</p>
          <p>{supplier?.address}</p>
          <p>{supplier?.taxid}</p>
        </div>
      ),
    },
  ];

  const detailsColumns = [
    {
      title: "",
      dataIndex: "label",
      key: "1",
      width: "60%",
    },
    {
      title: "",
      dataIndex: "value",
      key: "1",
      width: "40%",
    },
  ];

  const summaryColumns = [
    {
      title: "Summary",
      dataIndex: "label",
      key: "1",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "value",
      key: "2",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "label1",
      key: "3",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "value1",
      key: "4",
      width: "20%",
    },
  ];

  const transactionsColumns = [
    {
      title: "Account",
      dataIndex: "account",
      width: "30%",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "15%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "10%",
      render: (name: any, record: any) =>
        record.type === ETransactionType.EXPENSE ? (
          <span className="text-danger">{Currency(record.debit)}</span>
        ) : (
          <span className="text-success">{Currency(record.credit)}</span>
        ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "20%",
    },
    {
      title: "Notes",
      dataIndex: "note",
      width: "25%",
    },
  ];

  return (
    <Drawer
      title={"Purchase Order #" + purchaseOrder?.pNumber}
      placement="right"
      closable={true}
      onClose={() => {
        navigate(ROUTE_PURCHASE_ORDERS);
      }}
      visible={true}
      destroyOnClose
      width={isMobile || printMode ? "100%" : "60%"}
      className="drawer-view"
      footer={
        <div className="d-none d-md-block text-end">
          <Button onClick={() => setPrintMode(!printMode)} type="primary">
            Print Mode
          </Button>
        </div>
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Table
            className="no-header-table"
            size="small"
            style={{ width: isMobile ? "100%" : "40%" }}
            pagination={false}
            dataSource={[
              {
                label: "Purchase Order Date",
                value: purchaseOrder?.invoicedatetime,
              },
              {
                label: "Reference",
                value: "#" + purchaseOrder?.refer,
              },
            ]}
            columns={detailsColumns}
          />
          <Table
            bordered
            pagination={false}
            dataSource={[{ supplierName: purchaseOrder?.company }]}
            columns={supplierColumns}
            scroll={{ x: 440 }}
          />
          <Table
            bordered
            pagination={false}
            dataSource={purchaseOrder?.purchase_items}
            columns={purchaseItemscolumns}
            scroll={{ x: 740 }}
          />
          <Table
            pagination={false}
            size="small"
            bordered
            dataSource={[
              {
                label: "Total Tax",
                value: Currency(purchaseOrder?.tax),
              },
              {
                label: "Sub Total",
                value: Currency(purchaseOrder?.subtotal),
                label1: "Status",
                value1: PurchaseOrderStatus(purchaseOrder?.status),
              },
              {
                label: "Shipping",
                value: Currency(purchaseOrder?.shipping),
                label1: "Total Paid",
                value1: Currency(purchaseOrder?.TotalPaymentDone),
              },
              {
                label: "Total",
                value: Currency(purchaseOrder?.total),

                label1: "Balance Due",
                value1: Currency(purchaseOrder?.PaymentDue),
              },
            ]}
            columns={summaryColumns}
          />

          <Divider />
          <h3>Transactions</h3>
          <Table
            className="pb-4"
            bordered
            pagination={false}
            dataSource={purchaseOrder?.transactions}
            columns={transactionsColumns}
            scroll={{ x: 740 }}
          />
        </Space>
      )}
    </Drawer>
  );
}

export default PurchaseOrderView;
