import React from "react";
import { Badge } from "antd";
import { EPurchaseOrderStatus } from "interfaces/purchaseOrder";

export default function PurchaseOrderStatus(key: EPurchaseOrderStatus | undefined) {
  switch (key) {
    case EPurchaseOrderStatus.DUE:
      return <Badge style={{ backgroundColor: "#ff6262" }} count="Due" />;
    case EPurchaseOrderStatus.PAID:
      return <Badge style={{ backgroundColor: "#5ed45e" }} count="Paid" />;
    case EPurchaseOrderStatus.PARTIAL:
      return <Badge style={{ backgroundColor: "#2DCEE3" }} count="Partial" />;
    case EPurchaseOrderStatus.CANCELED:
      return <Badge style={{ backgroundColor: "#FF7588" }} count="Canceled" />;

    default:
      return <></>;
  }
}
