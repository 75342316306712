export enum EAccountType {
  INCOME = "Income",
  ASSETS = "Assets",
  EXPENSES = "Expenses",
  LIABILITIES = "Liabilities",
  EQUITY = "Equity",
  BASIC = "Basic",
}

export interface IAccount {
  id: string;
  accountnumber: string;
  holdername: string;
  openingdate: string;
  lastbal: string;
  code: string;
  account_type: EAccountType;
}
