import React from "react";
import CategoryCaraousel from "./components/categoryCaraousel";
import CheckNewOrder from "./components/checkNewOrder";
import "./styles/index.scss";

function TvMenu() {
  return (
    <>
      <CategoryCaraousel />
      <CheckNewOrder />
    </>
  );
}

export default TvMenu;
