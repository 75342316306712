import { Button, Drawer, Form, Input, notification, Space } from "antd";
import { ENDPOINTS } from "config/Constants";
import { useStoreState } from "hooks";
import { ISupplier } from "interfaces/suppliers";
import React, { useState } from "react";
import { Api } from "services/Api";

interface IAddSupplier {
  visible: boolean;
  onClose: (supplier?: ISupplier) => void;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

function AddSupplier({ onClose, visible }: IAddSupplier) {
  const [loading, setLoading] = useState(false);
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await Api.post(ENDPOINTS.POST_SUPPLIER, values);
      if (response.data["success"] === 1) {
        onClose(response.data.data);
        notification.success({ message: "Supllier added successfully!" });
      }
    } catch (error) {
      console.log("error in creating supplier", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Drawer
      title="Add Supplier"
      placement="right"
      closable={true}
      onClose={(e) => {
        onClose();
      }}
      visible={visible}
      destroyOnClose
      width={isMobile ? "100%" : "320px"}
    >
      <Form
        name="addsupplier"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        <Space direction="vertical">
          <Form.Item
            label="Supplier"
            name="company"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contact Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contact No."
            name="phone"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Drawer>
  );
}

export default AddSupplier;
