import { IAccount } from "interfaces/account";
import React, { useState } from "react";
import AccountsTable from "./components/accountsTable";
import AddAccount from "./components/addAccount";
import PageHeading from "components/headings/headings";

function Accounts() {
  const [showAddAccount, setShowAddAccount] = useState(false);

  const onClose = (account?: IAccount) => {
    setShowAddAccount(false);
  };
  return (
    <div>
      <PageHeading label="Accounts"></PageHeading>
      <AccountsTable />
      <AddAccount visible={showAddAccount} onClose={onClose} />
    </div>
  );
}

export default Accounts;
