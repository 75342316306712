import SplashLoading from "components/splashLoading/SplashLoading";
import { ENDPOINTS, EXPIRES_KEY, TOKEN_KEY } from "config/Constants";
import { useStoreActions } from "hooks";
import { IApiResponse } from "interfaces/apiresponse";
import App from "pages/app/App";
import React, { useEffect, useState } from "react";
import { Api } from "services/Api";
import Storage from "services/Storage";
import { isExpired } from "services/utility";
import { Link } from "react-router-dom";
import { ROUTE_POS } from "config/Constants";
import { Button } from "antd";

function Splash() {
  const [loading, setloading] = useState(true);
  const setUser = useStoreActions((actions) => actions.account.setUser);
  const setConfigStore = useStoreActions(
    (actions) => actions.store.setConfigStore
  );

  useEffect(() => {
    const getAccountInfo = async () => {
      try {
        const response = await Api.get<any>(ENDPOINTS.ACCOUNTINFO);
        if (response.data.success === 1) {
          setUser(response.data.data);
        }
      } catch (e) {
        console.log("user is not logged in", e);
      }
    };

    const getStoreInfo = async () => {
      try {
        const response = await Api.get<any>(ENDPOINTS.GET_STORE_INFO + "/0");
        if (response.data.success === 1) {
          setConfigStore(response.data.data);
        }
      } catch (e) {
        console.log("user is not logged in", e);
      }
    };

    const oninit = async () => {
      let callAccount = false;
      let params;
      const token = Storage.getToken();
      if (token != null) {
        if (isExpired(token[EXPIRES_KEY])) {
          params = { old_token: token[TOKEN_KEY] };
          await getClientCredentials(params);
        } else {
          callAccount = true;
        }
      } else {
        await getClientCredentials(params);
      }
      if (callAccount) {
        await getAccountInfo();
        await getStoreInfo();
      }
      setloading(false);
    };

    oninit();
  }, [setUser, setConfigStore]);

  const getClientCredentials = async (params: Object | undefined) => {
    const response = await Api.post<any>(ENDPOINTS.CLIENT_CREDENTIALS, params);
    const data: IApiResponse = response.data;
    Storage.setToken({
      access_token: data.data.access_token,
      expires_in: data.data.expires_in * 1000 + new Date().getTime(),
    });
  };

  return (
    <div>
      {loading ? (
        <SplashLoading />
      ) : (
        <>
          <Link to={`${ROUTE_POS}`} target="_blank" rel="noopener noreferrer">
            <Button
              size="large"
              type="primary"
              shape="circle"
              className="fixed-bottom m-4"
            >
              POS
            </Button>
          </Link>
          <App />
        </>
      )}
    </div>
  );
}

export default Splash;
