import { ENDPOINTS } from "config/Constants";
import { ICustomer } from "interfaces/Customer";
import { Api } from "services/Api";

class CustomersServiceClass {
  searchCustomer = async (
    searchTerm: string
  ): Promise<ICustomer[] | undefined> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.GET_CUSTOMERS}&search=${searchTerm}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching Customers", error);
    }
  };
  addAddress = async (values: any): Promise<any> => {
    try {
      const response = await Api.post<any>(`${ENDPOINTS.POST_ADDRESS}`, {
        ...values,
      });
      return response.data.data;
    } catch (error) {
      console.log("error in adding address", error);
    }
  };
}

const CustomersService = new CustomersServiceClass();
Object.freeze(CustomersService);
export default CustomersService;
