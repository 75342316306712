import {
  Col,
  Form,
  Input,
  Row,
  FormInstance,
  Card,
  Modal,
  Button,
  Space,
  Select,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import productsService from "services/productServices";
import { useState } from "react";
import { TSimpleProduct } from "interfaces/product";
import { IPurchaseOrder } from "interfaces/purchaseOrder";
import { useStoreState } from "hooks";

interface IPurchaseItemCard {
  name: number;
  fieldKey: number;
  restField: any;
  remove(name: number): void;
  calculateItemRow(name: number): void;
  form: FormInstance<any>;
  calculatePurchase(): void;
  purchaseOrder?: IPurchaseOrder;
}

const { Option } = Select;
const defaultSelectValue = "null";

function PurchaseItemCard({
  name,
  fieldKey,
  restField,
  form,
  calculateItemRow,
  remove,
  calculatePurchase,
  purchaseOrder,
}: IPurchaseItemCard) {
  const [product, setProduct] = useState<TSimpleProduct | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  const Currency = (val: any) => `${config_currency_symbol} ${val}`;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchProductOptions = async () => {
    const productId = form.getFieldValue("purchase_items")[name]["pid"];
    const product = await productsService.fetchProductValues(productId);
    if (product) {
      setProduct(product);
    } else {
      setProduct(undefined);
    }
    setIsModalVisible(true);
  };

  const onProductOptionSelect = (value: string) => {
    const option = product?.options[0].option_value.find(
      (item) => String(item.product_option_value_id) === value
    );
    let items = form.getFieldsValue()["purchase_items"];
    items[name]["product"] = product?.name + " - " + option?.name;
    items[name]["product_option_value_id"] = option?.product_option_value_id;
    form.setFieldsValue({
      purchase_items: items,
    });
  };

  return (
    <>
      {purchaseOrder ? (
        <Modal
          title="Options"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {product?.options && product.options.length ? (
            <Select
              className="d-block"
              defaultValue={defaultSelectValue}
              onChange={onProductOptionSelect}
            >
              <Option value={defaultSelectValue} disabled>
                Select Option
              </Option>
              {product.options[0].option_value.map((option: any) => (
                <Option value={`${option.product_option_value_id}`}>
                  {option.name}
                </Option>
              ))}
            </Select>
          ) : (
            <p>No options</p>
          )}
        </Modal>
      ) : null}
      <Col xs={24} className="p-0">
        <Card
          size="small"
          hoverable
          title={form.getFieldValue("purchase_items")[name]["product"]}
          extra={
            <Space>
              {purchaseOrder ? (
                <Button type="primary" onClick={fetchProductOptions}>
                  Options
                </Button>
              ) : null}
              <MinusCircleOutlined
                onClick={() => {
                  remove(name);
                  calculatePurchase();
                }}
              />
            </Space>
          }
        >
          <Row>
            <Col span={24} className="p-1 mb-1 text-white bg-primary">
              <Row gutter={12}>
                <Col xs={8} md={5}>
                  Qty
                </Col>
                <Col xs={8} md={5}>
                  Cost
                </Col>
                <Col xs={8} md={4}>
                  Tax(%)
                </Col>
                <Col xs={12} md={5} className="d-none d-md-block">
                  Tax
                </Col>
                <Col xs={12} md={5} className="d-none d-md-block">
                  Amount
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={12}>
                <Col xs={8} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "qty"]}
                    fieldKey={[fieldKey, "qty"]}
                    label="Quantity"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      placeholder="Quantity"
                      onChange={() => calculateItemRow(name)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "price"]}
                    fieldKey={[fieldKey, "price"]}
                    label="Cost"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      placeholder="Cost"
                      onChange={() => calculateItemRow(name)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} md={4}>
                  <Form.Item
                    {...restField}
                    name={[name, "tax"]}
                    fieldKey={[fieldKey, "tax"]}
                    label="Tax%"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      placeholder="Tax"
                      onChange={() => calculateItemRow(name)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "totaltax"]}
                    fieldKey={[fieldKey, "totaltax"]}
                    label="Tax"
                    labelCol={{ xs: { span: 6 }, md: { span: 0 } }}
                    labelAlign="left"
                  >
                    <label>
                      {Currency(
                        form.getFieldValue("purchase_items")[name]["totaltax"]
                      )}
                    </label>
                  </Form.Item>
                </Col>
                <Col xs={12} md={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "subtotal"]}
                    fieldKey={[fieldKey, "subtotal"]}
                    label="Amount"
                    labelCol={{ xs: { span: 6 }, md: { span: 0 } }}
                    labelAlign="left"
                  >
                    <label>
                      {Currency(
                        form.getFieldValue("purchase_items")[name]["subtotal"]
                      )}
                    </label>
                  </Form.Item>
                </Col>
                <Col flex="1" className="d-none">
                  <Form.Item
                    {...restField}
                    name={[name, "pid"]}
                    fieldKey={[fieldKey, "pid"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "id"]}
                    fieldKey={[fieldKey, "id"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "discount"]}
                    fieldKey={[fieldKey, "discount"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "totaldiscount"]}
                    fieldKey={[fieldKey, "totaldiscount"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "product_option_value_id"]}
                    fieldKey={[fieldKey, "product_option_value_id"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}

export default PurchaseItemCard;
