import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Select,
  // Radio,
  DatePicker,
  // Space,
} from "antd";
import { ENDPOINTS, DATE_FORMAT, DATABASE_DATE_FORMAT } from "config/Constants";
import { useStoreState } from "hooks";
import { EAccountType, IAccount } from "interfaces/account";
import {
  ITransaction,
  ETransactionType,
  ETransactionPayMethod,
  ETransactionCategory,
} from "interfaces/transaction";
import React, { useState, useEffect } from "react";
import { Api } from "services/Api";
// import SearchSupplier from "components/addPurchaseOrder/components/searchSupplier";
// import SearchCustomer from "components/searchCustomer";
// import { ICustomer } from "interfaces/Customer";
import accountServices from "services/accountServices";
import moment from "moment";

interface IAddTransactions {
  visible: boolean;
  onClose: (transaction?: ITransaction) => void;
}

const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  /* types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  }, */
};
/* eslint-enable no-template-curly-in-string */

const TransactionTypes = [
  {
    value: ETransactionType.EXPENSE,
    label: `${ETransactionType.EXPENSE} / Debit`,
  },
  {
    value: ETransactionType.INCOME,
    label: `${ETransactionType.INCOME} / Credit`,
  },
];

const TransactionsCategory = [
  {
    value: ETransactionCategory.EXPENSES,
    label: ETransactionCategory.EXPENSES,
  },
  {
    value: ETransactionCategory.INCOME,
    label: ETransactionCategory.INCOME,
  },
];

const TransactionsMethod = [
  {
    value: ETransactionPayMethod.CASH,
    label: ETransactionPayMethod.CASH,
  },
];

const defaultSelectValue = "null";
const defaultCS = "customer";

function AddTransactions({ onClose, visible }: IAddTransactions) {
  const [loading, setLoading] = useState(false);
  // const [cs, setCS] = useState<string>(defaultCS);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  // const [billTo, setBillTo] = useState<string>();
  const [form] = Form.useForm();
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);
  const { user } = useStoreState((state) => state.account);

  useEffect(() => {
    form.setFieldsValue({
      cs: defaultCS,
      date: moment(),
      method: ETransactionPayMethod.CASH,
    });
    getAccounts();
  }, [form]);

  const getAccounts = async () => {
    const accounts = await accountServices.getAccounts();
    setAccounts(accounts ?? []);
  };

  const onFinish = async (values: any) => {
    const date = moment(values.date).format(DATABASE_DATE_FORMAT);
    const account = accounts.find((item) => item.id === values.acid);
    const payload = {
      ...values,
      date,
      payer: "",
      payerid: 0,
      debit: values.type === ETransactionType.EXPENSE ? values.amount : "0.00",
      credit: values.type === ETransactionType.INCOME ? values.amount : "0.00",
      tid: "0",
      eid: user?.user_id,
      ext: "0",
      account: account?.holdername,
    };
    setLoading(true);
    try {
      const response = await Api.post(ENDPOINTS.POST_TRANSACTIONS, payload);
      if (response.data["success"] === 1) {
        onClose(response.data.data);
        notification.success({ message: "Account added successfully!" });
      }
    } catch (error) {
      console.log("error in creating account", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const onSupplierSelect = (option: {
  //   key: number;
  //   value: number;
  //   children: string;
  // }) => {
  //   if (option) {
  //     form.setFieldsValue({
  //       payer: option.children,
  //       payerid: option.value,
  //     });
  //     setBillTo(option.children);
  //   } else {
  //     form.setFieldsValue({
  //       payer: null,
  //       payerid: null,
  //     });
  //     setBillTo("");
  //   }
  // };

  // const onCustomerSelect = (option: ICustomer | undefined) => {
  //   if (option) {
  //     form.setFieldsValue({
  //       payer: option.firstname + option.lastname,
  //       payerid: option.customer_id,
  //     });
  //     setBillTo(option.firstname + option.lastname);
  //   } else {
  //     form.setFieldsValue({
  //       payer: null,
  //       payerid: null,
  //     });
  //     setBillTo("");
  //   }
  // };

  // const onCSChange = (e: any) => {
  //   setCS(e.target.value);
  //   form.setFieldsValue({
  //     payer: null,
  //     payerid: null,
  //   });
  //   setBillTo("");
  // };

  const onAccountChange = (e: any) => {
    const account = accounts.find((item) => item.id === e);
    let transactiontype = defaultSelectValue;
    switch (account?.account_type) {
      case EAccountType.INCOME:
        transactiontype = ETransactionType.INCOME;
        break;
      case EAccountType.EXPENSES:
        transactiontype = ETransactionType.EXPENSE;
        break;

      default:
        break;
    }
    form.setFieldsValue({
      type: transactiontype,
    });
  };

  return (
    <Drawer
      title="Add Transaction"
      placement="right"
      closable={true}
      onClose={() => {
        onClose();
      }}
      visible={visible}
      destroyOnClose
      width={isMobile ? "100%" : "50%"}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
          >
            Submit
          </Button>
        </div>
      }
    >
      <Form
        name="addTransaction"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        scrollToFirstError
        form={form}
      >
        <Row gutter={16}>
          {/* <Col xs={24} md={6}>
            <Form.Item name="cs" rules={[{ required: true }]}>
              <Radio.Group onChange={onCSChange}>
                <Space direction="vertical">
                  <Radio key="customer" value="customer">
                    Customer
                  </Radio>
                  <Radio key="supplier" value="supplier">
                    Supplier
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={18}>
            {cs === "supplier" ? (
              <Form.Item name="payer" rules={[{ required: true }]}>
                <SearchSupplier handleSelect={onSupplierSelect} />
              </Form.Item>
            ) : null}

            {cs === "customer" ? (
              <Form.Item name="payer" rules={[{ required: true }]}>
                <SearchCustomer handleSelect={onCustomerSelect} />
              </Form.Item>
            ) : null}
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="C/o" rules={[{ required: true }]}>
              <label>{billTo}</label>
            </Form.Item>
          </Col> */}
          <Col xs={24} md={8}>
            <Form.Item label="Account" name="acid" rules={[{ required: true }]}>
              <Select
                defaultValue={defaultSelectValue}
                onChange={onAccountChange}
              >
                <Option value={defaultSelectValue} disabled>
                  Select account
                </Option>
                {accounts.map((acc) => (
                  <Option value={acc.id}>
                    {acc.holdername} - {acc.accountnumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Account Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select defaultValue={defaultSelectValue}>
                <Option value={defaultSelectValue} disabled>
                  Select Type
                </Option>
                {TransactionTypes.map((type) => (
                  <Option value={type.value}>{type.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Category" name="cat" rules={[{ required: true }]}>
              <Select defaultValue={defaultSelectValue}>
                <Option value={defaultSelectValue} disabled>
                  Select Type
                </Option>
                {TransactionsCategory.map((type) => (
                  <Option value={type.value}>{type.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, type: "object" as const }]}
            >
              <DatePicker className="d-block" format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Payment Method"
              name="method"
              rules={[{ required: true }]}
            >
              <Select>
                {TransactionsMethod.map((type) => (
                  <Option value={type.value}>{type.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item label="Notes" name="note">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} md={16} className="d-none">
            <Form.Item name="payerid">
              <Input />
            </Form.Item>
            <Form.Item name="payer">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default AddTransactions;
