import { Select } from "antd";
import { ISupplier } from "interfaces/suppliers";
import { debounce } from "lodash-es";
import SuppliersService from "pages/suppliers/services/suppliersService";
import React, { useState } from "react";

const { Option } = Select;

interface ISearchSupplier {
  handleSelect: (option: {
    key: number;
    value: number;
    children: string;
  }) => void;
  supplier?: ISupplier;
}

function SearchSupplier({ handleSelect, supplier }: ISearchSupplier) {
  const [suppliers, setSuppliers] = useState<ISupplier[]>(
    supplier ? [supplier] : []
  );
  const [value, setValue] = useState<string | undefined>(supplier?.id);

  const handleSearch = debounce(async (value: string) => {
    if (value) {
      const suppliers = await SuppliersService.searchSupplier(value);
      setSuppliers(suppliers ?? []);
    } else {
      setSuppliers([]);
    }
  }, 400);

  const handleChange = (value: string, option: any) => {
    setValue(value);
    handleSelect(option);
  };

  const options = suppliers.map((supplier) => (
    <Option key={supplier.id} value={supplier.id}>
      {supplier.name}
    </Option>
  ));

  return (
    <Select
      allowClear
      showSearch
      style={{ minWidth: "200px" }}
      value={value}
      placeholder={"Search suppliers"}
      defaultActiveFirstOption={false}
      filterOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={<h4>no content found</h4>}
    >
      {options}
    </Select>
  );
}

export default SearchSupplier;
