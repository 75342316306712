import account, { IAccount } from "./account";
import globalConfig, { IGlobalConfig } from "./globalConfig";
import store, { IConfigStore } from "./store";

export interface IStore {
  account: IAccount;
  globalConfig: IGlobalConfig;
  store: IConfigStore;
}

const model: IStore = {
  account,
  globalConfig,
  store,
};

export default model;
