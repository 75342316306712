import React from "react";

function NotFound() {
  return (
    <div>
      <h2>Page not found</h2>
    </div>
  );
}

export default NotFound;
