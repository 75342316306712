import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import {
  ActionItem,
  IGenericTable,
  ITableDataTypes,
  ITableState,
} from "interfaces/genericTable";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Api } from "services/Api";

const defaultPaginationConfig: TablePaginationConfig = {
  responsive: true,
  showSizeChanger: true,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

type GenericTableHandle = {
  refreshTable: (reset: boolean) => void;
};

function GenericTable<T extends ITableDataTypes>(
  {
    rowKey = "id",
    columns,
    endpoint,
    bordered = true,
    sticky = true,
    paginationConfig = {},
    enablePagination = true,
    tableActions = [],
    tableActionCallback,
    scroll,
  }: IGenericTable<T>,
  ref: React.Ref<GenericTableHandle> | undefined
) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useState<ITableState>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    searchTerm: "",
    total: 0,
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[]
  ) => {
    // console.log("pagination", pagination);
    // console.log("filters", filters);
    // console.log("sorter", sorter);
    setTableState((state) => {
      return {
        ...state,
        pagination: {
          current: pagination.current || 1,
          pageSize: pagination.pageSize || 10,
        },
      };
    });
  };

  const getEndpoint = (): string => {
    let url = endpoint;
    const {
      pagination: { pageSize, current },
      searchTerm,
    } = tableState;
    if (enablePagination) {
      url += `&limit=${pageSize}&page=${current}`;
    }
    if (searchTerm) url += `&search=${searchTerm}`;
    return url;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Api.get(getEndpoint());
      setData(response.data.data);
      setTableState((state) => {
        return { ...state, total: response?.headers["x-total-count"] };
      });
    } catch (error) {
      console.log("error in fetching suppliers", error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (searchTerm: string) => {
    setTableState((state) => {
      return {
        ...state,
        pagination: {
          current: 1,
          pageSize: 10,
        },
        searchTerm,
      };
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      refreshTable(reset: boolean) {
        if (reset) {
          setTableState((state) => {
            return {
              ...state,
              pagination: {
                current: 1,
                pageSize: 10,
              },
              searchTerm: "",
            };
          });
        }
        fetchData();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState.pagination, tableState.searchTerm]);

  return (
    <Table<T>
      rowKey={rowKey}
      columns={columns}
      dataSource={data}
      bordered={bordered}
      scroll={scroll}
      title={() => (
        <TableHeader
          onSearch={onSearch}
          tableActions={tableActions}
          tableActionCallback={tableActionCallback}
        />
      )}
      sticky={sticky}
      pagination={
        enablePagination
          ? {
              ...defaultPaginationConfig,
              ...paginationConfig,
              ...tableState.pagination,
              total: tableState.total,
            }
          : false
      }
      onChange={handleTableChange}
      loading={loading}
    />
  );
}

export default forwardRef(GenericTable);

interface ITableHeader {
  onSearch: (searchTerm: string) => void;
  tableActions: ActionItem[];
  tableActionCallback?: (type: string) => void;
}

function TableHeader({
  onSearch,
  tableActions,
  tableActionCallback = (type) => {
    return;
  },
}: ITableHeader) {
  const { Search } = Input;

  return (
    <>
      <Row justify="space-between" gutter={[12,12]}>
        <Col xs={24} sm={24} md={10} className="mt-3 mt-md-0">
          <Search
            placeholder="input search text"
            allowClear
            onSearch={onSearch}
          />
        </Col>
        <Col flex="auto"></Col>
        <Col>
          <Space>
            {tableActions.map((action) => (
              <Button
                key={action.type}
                type="primary"
                icon={action.icon}
                onClick={() => tableActionCallback(action.type)}
              >
                {action.title}
              </Button>
            ))}
          </Space>
        </Col>
      </Row>
    </>
  );
}
