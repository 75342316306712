import { Card } from "antd";
import React from "react";
import IMenuProduct from "../interface/product";

interface IProductCard {
  product: IMenuProduct;
}

function ProductCard({ product }: IProductCard) {
  return (
    <Card className="mb-4" cover={<img alt="example" src={product.image} />}>
      <Card.Meta
        title={
          <>
            {product.name}
            <p className="mb-0">
              {parseFloat(product.weight).toFixed(2)} {product.weight_class}
            </p>
            <span className="h2 text-primary fw-bold fs-3">
              {product.special === 0
                ? product.price_formated
                : product.special_formated}
            </span>
          </>
        }
      />
    </Card>
  );
}

export default ProductCard;
