export const TOKEN_KEY = "access_token";
export const EXPIRES_KEY = "expires_in";
export const API_TOKEN = process.env.REACT_APP_TOKEN;

export const SIDERWIDTH = 200;

export const ENDPOINTS = {
  CLIENT_CREDENTIALS: "/api/rest/oauth2/token/client_credentials",
  LOGIN: "/api/rest_admin/login",
  ACCOUNTINFO: "/api/rest_admin/user",
  POST_SUPPLIER: "/api/rest_admin/suppliers",
  GET_SUPPLIERS: "/index.php?route=rest/supplier/suppliers",
  GET_SUPPLIER: "/api/rest_admin/suppliers",
  GET_ACCOUNTS: "/api/rest_admin/pos/accounts",
  POST_ACCOUNTS: "/api/rest_admin/pos/accounts",
  GET_PURCHASE_ORDERS: "/index.php?route=rest/purchase/purchases",
  GET_PRODUCT_BY_FIELDS:
    "index.php?route=feed/rest_api/products&simple=0&custom_fields=",
  POST_PURCHASE_ORDER: "api/rest_admin/purchaseorder",
  GET_TRANSACTIONS: "index.php?route=rest/transaction/transactions",
  POST_TRANSACTIONS: "/api/rest_admin/transactions",
  GET_CUSTOMERS: "/index.php?route=rest/customer_admin/customers",
  SUPPLIER_STATS: "/api/rest_admin/suppliers/stats",
  GET_STORE_INFO: "/api/rest_admin/stores",
  GET_PRODUCT_BY_FIELDS_BY_ID: (id: string, fields: string) =>
    `/api/rest/h-products/product/${id}/customfields/${fields}`,
  GET_CATEGORY_BY_LEVEL: "/api/rest_admin/categories/level",
  GET_CATEGORY_BY_PARENT_ID: "/api/rest_admin/categories/parent",
  ENABLE_DISABLE_CATEGORY:
    "/index.php?route=rest/category_admin/enable_disable_category",
  MAKE_ZERO_QTY: "/index.php?route=rest/category_admin/update_category",
  PRODUCTS_BY_CATEGORY_ID: "/api/rest_admin/products/category",
  PRODUCTS: "/api/rest_admin/products",
  COUNTRY: "/api/rest/countries",
  POST_ADDRESS: "/index.php?route=rest/account/addCustomerAddress",
  GET_SHIPPING: "index.php?route=rest/shipping_method/getshippingmethods",
  POST_ORDER_ADMIN: "index.php?route=rest/order_admin/createposorder",
};

export const ALLOWED_GROUPS = ["administrator", "tvmenu"];
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATABASE_DATE_FORMAT = "YYYY-MM-DD H:m:s";
export const ROUTE_SUPPLIERS = "/suppliers";
export const ROUTE_CUSTOMERS = "/customers";
export const ROUTE_PURCHASE_ORDERS = "/purchase-orders";
export const ROUTE_POS = "/pos";
export const POS = "POS";
