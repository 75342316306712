import { Modal, Select, Radio, Checkbox, Form, Divider } from "antd";
import { IProductOption } from "interfaces/product";

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "required!",
};
/* eslint-enable no-template-curly-in-string */

interface IOptionsModal {
  options?: IProductOption[];
  selected?: IProductOption[];
  onOk(value: any): void;
  visible: boolean;
  onCancel(): void;
}

enum OptionsTypes {
  RADIO = "radio",
  CHECKBOX = "checkbox",
  SELECT = "select",
}

function OptionsModal({ options, visible, onOk, onCancel }: IOptionsModal) {
  const [form] = Form.useForm();
  const defaultSelectValue = "null";

  const Content = ({ option }: { option: IProductOption }) => {
    switch (option.type) {
      case OptionsTypes.RADIO: {
        const values = option.option_value.map((item) => ({
          label: item.name,
          value: item.product_option_value_id,
        }));
        return (
          <Form.Item
            key={option.option_id}
            name={option.option_id}
            label={option.name}
            rules={[{ required: option.required === "1" }]}
          >
            <Radio.Group options={values} optionType="button" />
          </Form.Item>
        );
      }

      case OptionsTypes.SELECT:
        return (
          <Form.Item
            key={option.option_id}
            name={option.option_id}
            label={option.name}
            rules={[{ required: option.required === "1" }]}
          >
            <Select className="d-block">
              <Option key={-1} value={defaultSelectValue} disabled>
                Select Option
              </Option>
              {option.option_value.map((option: any) => (
                <Option
                  key={option.product_option_value_id}
                  value={option.product_option_value_id}
                >
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case OptionsTypes.CHECKBOX: {
        const values = option.option_value.map((item) => ({
          label: item.name,
          value: item.product_option_value_id,
        }));
        return (
          <Form.Item
            key={option.option_id}
            name={option.option_id}
            label={option.name}
            rules={[{ required: option.required === "1" }]}
          >
            <Checkbox.Group options={values} />
          </Form.Item>
        );
      }

      default:
        return null;
    }
  };

  const onFinish = (values: any) => {
    let optionsStr = "";
    const payload = Object.keys(values).map((item: any) => {
      const value = values[item];
      if (value) {
        const optionId = item as number;
        //change the value here
        const option = options?.find((op) => op.option_id === Number(optionId));
        if (option?.type === OptionsTypes.CHECKBOX && value.length) {
          optionsStr += `${option?.name} : `;
          const returnPayload = value.map((element: number | string) => {
            const selectedOption = option?.option_value.find(
              (item) => item.product_option_value_id === element
            );
            optionsStr += `${selectedOption?.name}, `;
            return {
              ...option,
              optionName: option?.name,
              name: selectedOption?.name,
              product_option_value_id: element,
            };
          });
          optionsStr = optionsStr.trim();
          optionsStr = optionsStr.replace(/,\s*$/, "");
          optionsStr += ` | `;
          return returnPayload;
        }

        const selectedOption = option?.option_value.find(
          (item) => item.product_option_value_id === value
        );

        optionsStr += `${option?.name} : ${selectedOption?.name} | `;
        return {
          ...option,
          optionName: option?.name,
          name: selectedOption?.name,
          product_option_value_id: value,
        };
      }

      return null;
    });

    let selectedOptions: any[] = [];
    payload.forEach((item) => {
      if (item instanceof Array && item.length) {
        selectedOptions = selectedOptions.concat(item);
      } else if (item) {
        selectedOptions = [...selectedOptions, item];
      }
    });
    optionsStr = optionsStr.trim();
    optionsStr = optionsStr.slice(0, -1);
    onOk({ selectedOptions, optionsStr });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="Options"
      visible={visible}
      onOk={handleOk}
      okText="Add"
      onCancel={onCancel}
      destroyOnClose
    >
      {options && options.length ? (
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          onFinishFailed={onFinishFailed}
        >
          {options.map((option) => (
            <>
              <Content key={option.option_id} option={option} />
              <Divider />
            </>
          ))}
        </Form>
      ) : (
        <p>No options</p>
      )}
    </Modal>
  );
}

export default OptionsModal;
