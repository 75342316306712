import { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import localisationService from "services/localisationServices";
import customerService from "services/customerServices";
import { ICountry } from "interfaces/localisation";
import { ICustomerAddress } from "interfaces/Customer";

interface IAddAddress {
  visible: boolean;
  onAdd: (addressId: ICustomerAddress) => void;
  onClose: () => void;
  customerId: number | string;
}
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  /* types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  }, */
};
/* eslint-enable no-template-curly-in-string */

function AddAddress({ visible, onAdd, onClose, customerId }: IAddAddress) {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countryData, setCountryData] = useState<ICountry>();
  const handleOk = () => {
    form.submit();
    // onAdd("");
  };

  const onFinish = async (values: any) => {
    values = {
      ...values,
      default: 0,
      postcode: "",
      city: "",
      company: "",
      customer_id: customerId,
    };
    const response = await customerService.addAddress(values);
    if (response && response.address_id) {
      values = {
        ...values,
        address_id: response.address_id,
      };
      onAdd(values as ICustomerAddress);
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getCountries = async () => {
    const countries = await localisationService.getCountries();
    setCountries(countries ?? []);
  };

  const getZones = async (value: number) => {
    const countryData = await localisationService.getZonesByCountry(value);
    setCountryData(countryData ?? ({} as ICountry));
  };

  useEffect(() => {
    getCountries();
  }, []);

  function handleCityChange(value: number) {
    getZones(value);
  }

  return (
    <Modal
      title="Add Address"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      destroyOnClose
      okText="Add"
      cancelText="Close"
    >
      <Form
        name="address-form"
        form={form}
        labelCol={{ span: 6 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Firstname"
          labelAlign="left"
          name="firstname"
          rules={[{ required: true }]}
        >
          <Input placeholder="Firstname" />
        </Form.Item>
        <Form.Item
          label="Lastname"
          labelAlign="left"
          name="lastname"
          rules={[{ required: true }]}
        >
          <Input placeholder="Lastname" />
        </Form.Item>
        <Form.Item
          label="Address 1"
          labelAlign="left"
          name="address_1"
          rules={[{ required: true }]}
        >
          <Input placeholder="Address 1" />
        </Form.Item>
        <Form.Item label="Address 2" labelAlign="left" name="address_2">
          <Input placeholder="Address 2" />
        </Form.Item>
        {/* Marked as Country in Opencart */}
        <Form.Item
          label="City"
          labelAlign="left"
          name="country_id"
          rules={[{ required: true }]}
        >
          <Select
            className="d-block"
            placeholder="City"
            onChange={handleCityChange}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((item) => (
              <Option value={item.country_id} key={item.country_id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Area"
          labelAlign="left"
          name="zone_id"
          rules={[{ required: true }]}
        >
          <Select
            className="d-block"
            placeholder="Area"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryData
              ? countryData?.zone.map((item) => (
                  <Option value={item.zone_id} key={item.zone_id}>
                    {item.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default AddAddress;
