import { Drawer, Table, Divider, Button, Row, Col, Form, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import ShippingServices from "services/shippingServices";
import "./styles/index.scss";
import SearchCustomer from "components/searchCustomer";
import { ICustomer, ICustomerAddress } from "interfaces/Customer";
import SearchProduct from "./components/searchProduct";
import { TSimpleProduct } from "interfaces/product";
import AddAddress from "./components/addAddress";
import InvoiceItemCard from "./components/invoiceItemCard";
import { useStoreState } from "hooks";
import { IShipping } from "interfaces/Shipping";
import { POS as POSConstant } from "config/Constants";
// import { Api } from "services/Api";
// import { ENDPOINTS } from "config/Constants";
import CashPaymentModal from "./components/cashPaymentModal";

const { TextArea } = Input;

type PaymentMethods = "CASH" | "CARD" | "ONLINE";

function POS() {
  const [form] = Form.useForm();
  const [customer, setCustomer] = useState<ICustomer>();
  const [address, setAddress] = useState<ICustomerAddress>();
  const [shipping, setShipping] = useState<IShipping>();
  const [shippingMethods, setShippingMethods] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState<{ tax: number; amount: number }[]>([]);
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  const Currency = (val: any) => `${config_currency_symbol} ${val}`;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (address: ICustomerAddress) => {
    let temp: ICustomer | undefined = customer;
    if (temp) temp = { ...temp, addresses: [...temp.addresses, address] };
    setCustomer(temp);
    form.setFieldsValue({
      address_id: address.address_id,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOkCashModal = () => {
    // setShowCashModal(false);
    form.submit();
  };
  const handleCancelCashModal = () => {
    setShowCashModal(false);
    resetShipping();
  };

  const resetShipping = () => {
    form.setFieldsValue({
      isDeliveryNeeded: false,
      shipping: 0,
      address_id: null,
      shipping_method: null,
    });
    calculatePurchase();
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    /* types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  }, */
  };
  /* eslint-enable no-template-curly-in-string */

  const onCustomerSelect = (option: ICustomer | undefined) => {
    if (option) {
      console.log(option);
      setCustomer(option);
      form.setFieldsValue({
        customer: option.customer_id,
      });
    } else {
      resetValues();
    }
  };

  // const handleDeliveryChange = (e: any) => {
  //   setShowAddress(form.getFieldValue("isDeliveryNeeded"));
  // };

  const getPaymentDetails = () => {
    switch (paymentMethod) {
      case "CASH":
        return {
          title: "Cash On Delivery",
          code: "cod",
        };
      case "CARD":
        return {
          title: "",
          code: "",
        };
      case "ONLINE":
        return {
          title: "",
          code: "",
        };

      default:
        return {
          title: "",
          code: "",
        };
    }
  };

  const onFinish = async (values: any) => {
    const { total, invoice_items, comment } = values;
    const {
      customer_id,
      customer_group_id,
      firstname,
      lastname,
      email,
      telephone,
    } = customer ? customer : ({} as ICustomer);

    let selectedAddress;
    if (address) {
      selectedAddress = {
        firstname: address.firstname,
        lastname: address.lastname,
        company: address.company,
        address_1: address.address_1,
        address_2: address.address_2,
        city: address.city,
        postcode: address.postcode,
        zone_id: address.zone_id,
        zone: address.zone,
        country: address.country,
        country_id: address.country_id,
      };
    }

    const paymentDetails = getPaymentDetails();

    const payload = {
      comment: comment ? comment : "",
      total: total,
      platform: POSConstant,
      customer: {
        customer_id,
        customer_group_id,
        firstname,
        lastname,
        email,
        telephone,
      },
      payment_address: selectedAddress,
      payment_method: {
        title: paymentDetails.title,
        code: paymentDetails.code,
      },
      shipping_address: selectedAddress,
      shipping_method: {
        title: shipping?.title,
        code: shipping?.code,
      },
      products: invoice_items,
    };

    console.log(payload);

    if (paymentDetails.code) {
      setLoading(true);
      try {
        // const response: any = await Api.post(
        //   ENDPOINTS.POST_ORDER_ADMIN,
        //   payload
        // );
        // if (response.data["success"] === 1) {
        //   // onClose(response.data.data);
        //   console.log(response.data.data);
        //   notification.success({ message: "Order added successfully!" });
        // }
      } catch (error) {
        console.log("error in creating order", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const confirmExit = () => {
    return "show warning";
  };
  window.onbeforeunload = confirmExit;

  const calculateTotals = useCallback(() => {
    const shipping = form.getFieldValue("shipping");
    const discount = form.getFieldValue("total_discount");
    let items = form.getFieldsValue()["invoice_items"];
    let tax = 0;
    let total = 0;
    let total_qty = 0;

    items.forEach((item: any) => {
      tax += Number(item.totaltax);
      total += Number(item.subtotal);
      total_qty += Number(item.quantity);
    });
    let subtotal = total;
    if (shipping) {
      total += shipping * 1;
    }
    if (discount) {
      total -= discount * 1;
      subtotal -= discount * 1;
    }
    form.setFieldsValue({
      tax: Number(tax).toFixed(3),
      total: Number(total).toFixed(3),
      subtotal: Number(subtotal).toFixed(3),
      total_qty,
      total_items: items ? items.length : 0,
    });
  }, [form]);

  const calculateTaxDistribution = useCallback(() => {
    let items = form.getFieldsValue()["invoice_items"];
    setTaxes([]);
    let updatedTaxes: { tax: number; amount: number }[] = [];
    items.forEach((item: any) => {
      if (item.tax) {
        const row = updatedTaxes.find((e) => e.tax === Number(item.tax));
        const rowIndex = updatedTaxes.findIndex(
          (e) => e.tax === Number(item.tax)
        );
        if (row) {
          updatedTaxes[rowIndex] = {
            tax: Number(row.tax),
            amount: Number(row.amount) * 1 + Number(item.totaltax),
          };
        } else {
          updatedTaxes = [
            ...updatedTaxes,
            { tax: Number(item.tax), amount: Number(item.totaltax) },
          ];
        }
      }
    });
    setTaxes(updatedTaxes);
  }, [form]);

  const calculatePurchase = useCallback(() => {
    calculateTotals();
    calculateTaxDistribution();
  }, [calculateTotals, calculateTaxDistribution]);

  const resetValues = useCallback(() => {
    //set default values
    form.setFieldsValue({
      address_id: null,
      customer: 0,
      invoice_items: [],
      total_items: 0,
      total_qty: 0,
    });
    setCustomer(defaultCustomer);
  }, [form]);
  useEffect(() => {
    resetValues();
  }, [resetValues]);

  const defaultCustomer = () => ({ firstname: "Walk-in" } as ICustomer);

  const onProductSelect = (product: TSimpleProduct | undefined) => {
    addProduct(product ?? ({} as TSimpleProduct));

    const items = document.getElementById("items-div");
    //scroll code here
  };

  const addProduct = (product: TSimpleProduct) => {
    // const product = form.getFieldsValue()["search_product"];
    if (product) {
      let items = form.getFieldsValue()["invoice_items"];
      const { price, name, product_id, special } = product;
      //check product in existing list
      const existingRow = items.find((i: any) => i.product_id === product_id);
      const existingRowIndex = items.findIndex(
        (i: any) => i.product_id === product_id
      );

      // hardcoded
      const tax: number = 12;
      const taxRate = (tax * 1 + 100) / 100;
      const cost = price / taxRate;
      const tax1 = cost * (tax / 100);
      const ratePrice = special > 0 ? special : price;

      if (existingRowIndex < 0) {
        items = [
          ...items,
          {
            product_id: product_id,
            options: [],
            product: `${name?.trim()}`,
            quantity: 1,
            tax: tax,
            price: ratePrice,
            subtotal: Number((1 * ratePrice).toFixed(3)),
            totaltax: Number((1 * tax1).toFixed(3)),
            discount: "0.00",
            totaldiscount: "0.00",
            productObj: product,
          },
        ];
      } else {
        let values = { ...existingRow, quantity: existingRow.quantity * 1 + 1 };
        items[existingRowIndex] = values;
        calculateItemRow(existingRowIndex);
      }

      form.setFieldsValue({ invoice_items: items });
      calculatePurchase();
    }
  };

  const calculateItemRow = (rowIndex: number) => {
    let items = form.getFieldsValue()["invoice_items"];
    let values = items[rowIndex];
    // calculation based on tax inclusive
    const { quantity, tax, price } = values;
    const taxRate = (tax * 1 + 100) / 100;
    const cost = price / taxRate;
    const tax1 = cost * (tax / 100);
    values = {
      ...values,
      subtotal: Number((quantity * price).toFixed(3)),
      totaltax: Number((quantity * tax1).toFixed(3)),
    };
    items[rowIndex] = values;
    form.setFieldsValue({ invoice_items: items });
    calculatePurchase();
  };

  const handleAddressChange = (value: any) => {
    console.log(value);
    if (customer) {
      const selectedAddress = customer.addresses.find(
        (item) => item.address_id === value
      );
      setAddress(selectedAddress ?? ({} as ICustomerAddress));
    }
    getShippingMethods(value);
  };

  const handleShippingChange = (value: any) => {
    const shippingMethod: IShipping = shippingMethods[value];
    setShipping(shippingMethod ?? {});
    form.setFieldsValue({ shipping: shippingMethod.cost });
    calculatePurchase();
  };

  const getShippingMethods = async (addressId: number | string) => {
    const response = await ShippingServices.getShippimgMethods(addressId);
    const methods = response.xshippingpro.quote;
    setShippingMethods(methods ?? []);
  };

  const createInvoice = (paymentMethod: PaymentMethods) => {
    setPaymentMethod(paymentMethod);
    setShowCashModal(true);
    // form.submit();
  };

  // const TaxDistribution = () => (
  //   <Col span="10">
  //     {taxes && taxes.length ? (
  //       <>
  //         <label>
  //           <b>Tax Distribution</b>
  //         </label>
  //         <Table
  //           className="pt-2"
  //           bordered
  //           size={"small"}
  //           pagination={false}
  //           dataSource={taxes}
  //           rowKey="tax"
  //           footer={() => (
  //             <Row>
  //               <Col flex="1">Total Tax</Col>
  //               <Col flex="1" className="text-end">
  //                 {Currency(form.getFieldValue("tax"))}
  //               </Col>
  //             </Row>
  //           )}
  //           columns={[
  //             {
  //               title: "Tax(%)",
  //               dataIndex: "tax",
  //               key: "tax",
  //               render: (text) => <span>{text}%</span>,
  //             },
  //             {
  //               title: "Amount",
  //               dataIndex: "amount",
  //               align: "right",
  //               key: "tax",
  //               render: (text) => <span>{Currency(text)}</span>,
  //             },
  //           ]}
  //         />
  //       </>
  //     ) : null}
  //   </Col>
  // );

  const Totals = () => (
    <Col span="24">
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            name="total_qty"
            className="m-0 mb-2"
            label="Total Quantity"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            {/* <Input type="number" placeholder="Total Quantity" /> */}
            <label>{form.getFieldValue("total_qty")}</label>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="total_items"
            className="m-0 mb-2"
            label="Total Items"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            {/* <Input type="number" placeholder="Total Items" /> */}
            <label>{form.getFieldValue("total_items")}</label>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="total_discount"
            className="m-0 mb-2"
            label="Discount"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            <Input
              type="number"
              placeholder="Discount"
              onChange={calculatePurchase}
            />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
        <Col span={12} className="d-none">
          <Form.Item
            name="shipping"
            className="m-0 mb-2"
            label="Shipping"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            <Input
              type="number"
              placeholder="Shipping"
              onChange={calculatePurchase}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="subtotal"
            className="m-0 mb-2"
            label="Sub Total"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            <Input type="number" placeholder="Sub Total" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="total"
            className="m-0 mb-2"
            label="Total"
            labelAlign="left"
            labelCol={{ span: 8 }}
          >
            <Input type="number" placeholder="Total" />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );

  const CommentBox = () => (
    <Col span={24} className="mb-2">
      <Form.Item
        name="comment"
        className="m-0 mb-2"
        label="Comment"
        labelCol={{ span: 0 }}
      >
        <TextArea rows={2} placeholder="Comment" />
      </Form.Item>
    </Col>
  );

  const hasError = () => {
    if (form.getFieldValue("total")) {
      return form.getFieldValue("total") <= 0;
    }
    return true;
  };

  return (
    <>
      <Drawer
        placement="right"
        closable={true}
        onClose={() => {
          // onClose();
        }}
        visible={true}
        destroyOnClose
        closeIcon={null}
        width={"100%"}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => createInvoice("CASH")}
              type="primary"
              style={{ marginRight: 8 }}
              loading={loading}
              disabled={hasError()}
            >
              CASH
            </Button>
            <Button
              onClick={() => createInvoice("CARD")}
              type="primary"
              style={{ marginRight: 8 }}
              loading={loading}
            >
              CARD
            </Button>
            <Button
              onClick={() => createInvoice("ONLINE")}
              type="primary"
              loading={loading}
            >
              ONLINE
            </Button>
          </div>
        }
      >
        <Form
          name="pos"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          scrollToFirstError
          form={form}
        >
          <Row>
            <Col xs={24} md={12} className="p-2">
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="customer"
                    label="Customer"
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                    className="m-0 mb-2"
                  >
                    <SearchCustomer handleSelect={onCustomerSelect} />
                  </Form.Item>
                </Col>
                {/* {customer ? (
                  <Col span={24} className="m-0 mb-1 p-1">
                    <p className="m-0">
                      Bill to:{" "}
                      <b>
                        {customer?.firstname}{" "}
                        {customer?.telephone
                          ? "(" + customer?.telephone + ")"
                          : null}
                      </b>
                    </p>
                  </Col>
                ) : null} */}
                {/* <Col span={6}>
                  <Form.Item
                    name="isDeliveryNeeded"
                    valuePropName="checked"
                    className="m-0"
                  >
                    <Checkbox onChange={handleDeliveryChange}>
                      Needs delivery
                    </Checkbox>
                  </Form.Item>
                </Col>
                {showAddress ? (
                  <>
                    <CustomerAddress />
                    <Col span={6}></Col>
                    {shippingMethods ? <ShippingMethods /> : null}
                  </>
                ) : null} */}

                <Col
                  span="24"
                  className="overflow-auto"
                  style={{ maxHeight: "500px", height: "500px" }}
                  id="items-div"
                >
                  <Divider>Invoice Items</Divider>
                  <Row>
                    <Col span={24} className="p-1 mb-1 text-white bg-primary">
                      <Row gutter={12}>
                        <Col xs={8} md={5}>
                          Qty
                        </Col>
                        <Col xs={8} md={5}>
                          Cost
                        </Col>
                        <Col xs={8} md={4}>
                          Tax(%)
                        </Col>
                        <Col xs={12} md={5} className="d-none d-md-block">
                          Tax
                        </Col>
                        <Col xs={12} md={5} className="d-none d-md-block">
                          Amount
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]} className="pb-2">
                    <Form.List name="invoice_items">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, index) => (
                              <InvoiceItemCard
                                key={key}
                                form={form}
                                fieldKey={fieldKey}
                                restField={restField}
                                name={name}
                                remove={remove}
                                calculatePurchase={calculatePurchase}
                                calculateItemRow={calculateItemRow}
                              />
                            )
                          )}
                        </>
                      )}
                    </Form.List>
                  </Row>
                </Col>
                <Col span="24" className="mt-3">
                  <Row gutter={[24, 0]}>
                    <CommentBox />
                    <Totals />
                    {/* <TaxDistribution /> */}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12} className="p-2">
              <Row>
                <Col span={24}>
                  <SearchProduct handleSelect={onProductSelect} />
                </Col>
              </Row>
            </Col>
          </Row>
          {showCashModal ? (
            <CashPaymentModal
              visible={showCashModal}
              total={form.getFieldValue("total")}
              onCancel={handleCancelCashModal}
              onOk={handleOkCashModal}
              handleAddressChange={handleAddressChange}
              handleShippingChange={handleShippingChange}
              shippingMethods={shippingMethods}
              customer={customer}
              showModal={showModal}
              resetShipping={resetShipping}
            />
          ) : null}
        </Form>
      </Drawer>
      <AddAddress
        visible={isModalVisible}
        onAdd={handleOk}
        onClose={handleCancel}
        customerId={customer ? customer.customer_id : "0"}
      />
    </>
  );
}

export default POS;
