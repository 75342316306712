import React from "react";
import { Outlet } from "react-router";
import Statistics from "./components/statistics";
import SuppliersTable from "./components/suppliersTable";

function SuppliersView() {
  return (
    <div>
      <Statistics />
      <div className="pt-3"></div>
      <SuppliersTable />
      <Outlet />
    </div>
  );
}

export default SuppliersView;
