import { Select, Image, Space } from "antd";
import { TSimpleProduct } from "interfaces/product";
import { debounce } from "lodash-es";
import productsService from "services/productServices";
import { useState } from "react";

const { Option } = Select;

interface ISearchProduct {
  handleSelect: (option: TSimpleProduct | undefined) => void;
}

function SearchProduct({ handleSelect }: ISearchProduct) {
  const [products, setProducts] = useState<TSimpleProduct[]>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = debounce(async (value: string) => {
    if (value) {
      const products = await productsService.searchProduct(value);
      setProducts(products ?? []);
    } else {
      setProducts([]);
    }
  }, 400);

  const handleChange = (value: string, option: any) => {
    setValue(value);
    const product = products?.find((item) => item.product_id === option?.value);
    handleSelect(product);
    if (product?.options && product?.options.length) {
    } else {
      setValue("");
    }
  };

  const options = products.map((product) => (
    <Option key={product.id} value={product.id}>
      <Space>
        <Image width={60} height={35} src={product.image} />
        {product.name}
      </Space>
    </Option>
  ));

  return (
    <Select
      allowClear
      showSearch
      style={{ minWidth: "200px" }}
      value={value}
      placeholder={"Search product"}
      defaultActiveFirstOption={false}
      filterOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={<h4>no content found</h4>}
    >
      {options}
    </Select>
  );
}

export default SearchProduct;
