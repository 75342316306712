import { ENDPOINTS } from "config/Constants";
import { IStats, ISupplier } from "interfaces/suppliers";
import { Api } from "services/Api";

class SuppliersServiceClass {
  fetchSupplier = async (id: string): Promise<ISupplier | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.GET_SUPPLIER}/${id}`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching suppliers", error);
    }
  };
  searchSupplier = async (
    searchTerm: string
  ): Promise<ISupplier[] | undefined> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.GET_SUPPLIERS}&search=${searchTerm}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching suppliers", error);
    }
  };
  getStats = async (): Promise<IStats | undefined> => {
    try {
      const response = await Api.get(`${ENDPOINTS.SUPPLIER_STATS}`);
      return response.data.data;
    } catch (error) {
      console.log("error in fetching suppliers", error);
    }
  };
}

const SuppliersService = new SuppliersServiceClass();
Object.freeze(SuppliersService);
export default SuppliersService;
