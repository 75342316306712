import { Input, Row, Col, List, Card, Typography } from "antd";
import { TSimpleProduct } from "interfaces/product";
import { debounce } from "lodash-es";
import productsService from "services/productServices";
import React, { useEffect, useState } from "react";
import { useStoreState } from "hooks";

const { Paragraph } = Typography;

interface ISearchProduct {
  handleSelect: (option: TSimpleProduct | undefined) => void;
}

const { Meta } = Card;
const defaultSearchTerm = "chicken";

function SearchProduct({ handleSelect }: ISearchProduct) {
  const inputRef = React.useRef<any>(null);
  const [products, setProducts] = useState<TSimpleProduct[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const { storeInfo } = useStoreState((state) => state.store);
  const { config_currency_symbol } = storeInfo;

  const Currency = (value: number | string | undefined) =>
    `${config_currency_symbol} ${value}`;

  const getProducts = async (value: string) => {
    setLoading(true);
    if (value) {
      const products = await productsService.searchProduct(value);
      setProducts(products ?? []);
      setLoading(false);
    } else {
      setProducts([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts(defaultSearchTerm);
  }, []);

  const handleClick = (product: TSimpleProduct) => {
    inputRef.current!.focus({
      cursor: "start",
    });
    handleSelect(product);
  };

  const onChange = debounce((e: any) => {
    const value = e.target.value;
    if (!value) {
      getProducts(defaultSearchTerm);
    } else {
      getProducts(value);
    }
  }, 500);

  return (
    <Row>
      <Col span={24} className="mb-3">
        <Input
          className="border border-primary"
          placeholder="Search product here...."
          allowClear
          onChange={onChange}
          ref={inputRef}
        />
      </Col>
      <Col span={24}>
        <List
          style={{ maxHeight: window.innerHeight - 170 }}
          loading={loading}
          className="product-list overflow-auto"
          grid={{
            gutter: 0,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 5,
            xl: 5,
            xxl: 5,
          }}
          dataSource={products}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleClick(item)}
              className="pos-product h-100"
            >
              <Card
                className="h-100 cursor-pointer"
                cover={<img alt={item.name} src={item.image} />}
              >
                <Meta
                  title={
                    <div>
                      <Paragraph className="m-0" ellipsis={{ rows: 2 }}>
                        {item.name}
                      </Paragraph>
                      <div style={{ height: "11px" }}>
                        <p className="cost m-0">Cost: {Currency(item.price)}</p>
                      </div>
                      <p className="mrp m-0">MRP: {Currency(item.price)}</p>
                    </div>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
}

export default SearchProduct;
