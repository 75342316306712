import { Drawer, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useStoreActions, useStoreState } from "hooks";
import { throttle } from "lodash-es";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPrivateRoutes } from "routes/routes";

function MainSidebar() {
  const navigate = useNavigate();
  const isSiderBarCollapsed = useStoreState(
    (state) => state.globalConfig.isSiderBarCollapsed
  );
  const isMobile = useStoreState((state) => state.globalConfig.isMobile);
  const setIsSiderBarCollapsed = useStoreActions(
    (actions) => actions.globalConfig.setIsSiderBarCollapsed
  );
  const setWindowSize = useStoreActions(
    (actions) => actions.globalConfig.setWindowSize
  );

  const handleLinkClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    const handleResize = throttle(() => {
      onResize();
    }, 500);

    // Handler to call on window resize
    function onResize() {
      setWindowSize(window.innerWidth);
      if (window.innerWidth < 992) {
        setIsSiderBarCollapsed(true);
      } else {
        setIsSiderBarCollapsed(false);
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSiderBarCollapsed, setWindowSize]);

  return isMobile ? (
    <Drawer
      visible={!isSiderBarCollapsed}
      placement="left"
      onClose={() => setIsSiderBarCollapsed(true)}
      bodyStyle={{ padding: "0" }}
      title="FreshKaka"
    >
      <SidebarMenu handleClick={handleLinkClick} />
    </Drawer>
  ) : (
    <Sider
      className="main-sider"
      collapsible
      breakpoint="lg"
      collapsedWidth="0"
      collapsed={isSiderBarCollapsed}
      trigger={null}
      theme="light"
    >
      <div className="logo" />
      <SidebarMenu handleClick={handleLinkClick} />
    </Sider>
  );
}

interface ISidebarMenu {
  handleClick: Function;
}

const SidebarMenu: React.FunctionComponent<ISidebarMenu> = ({
  handleClick,
}) => {
  const user = useStoreState((state) => state.account.user);
  const routes = getPrivateRoutes(user?.user_group);

  return (
    <Menu
      className="h-100"
      theme="light"
      mode="inline"
      defaultOpenKeys={["suppliers"]}
    >
      {routes
        .filter((item) => !item.hideFromMenu)
        .map((route, index) => {
          return (
            <Menu.Item
              onClick={() => handleClick(route.path)}
              icon={route.icon}
              key={route.path}
            >
              {route.title}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default MainSidebar;
