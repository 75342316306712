import PurchaseOrderTable from "components/purchaseOrderTable";
import { ENDPOINTS } from "config/Constants";
import PageHeading from "components/headings/headings";
import { Outlet } from "react-router";

function PurchaseOrders() {
  return (
    <div>
      <PageHeading label="Purchase Orders"></PageHeading>
      <PurchaseOrderTable
        actions={true}
        endpoint={ENDPOINTS.GET_PURCHASE_ORDERS}
      />
      <Outlet />
    </div>
  );
}

export default PurchaseOrders;
