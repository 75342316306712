import { Action, action } from "easy-peasy";
export interface IConfigStore {
  storeInfo: {
    config_currency_symbol: string;
    config_name?: string;
    config_telephone?: string;
    config_email?: string;
    config_address?: string;
  };
  setConfigStore: Action<IConfigStore, string>;
}

const store: IConfigStore = {
  storeInfo: { config_currency_symbol: "" },
  setConfigStore: action((state, payload: any) => {
    state.storeInfo = payload;
  }),
};

export default store;
