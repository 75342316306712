import { LogoutOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
import { useStoreActions, useStoreState } from "hooks";
import React from "react";
import { logout } from "services/utility";

const { Header } = Layout;

function MainHeader() {
  const globalConfig = useStoreState((state) => state.globalConfig);
  const setIsSiderBarCollapsed = useStoreActions(
    (actions) => actions.globalConfig.setIsSiderBarCollapsed
  );

  return (
    <Header
      className="main-header"
      style={{
        width: globalConfig.isSiderBarCollapsed
          ? "100%"
          : globalConfig.isMobile
          ? "100%"
          : "calc(100% - 200px)",
      }}
    >
      <Row justify="space-between">
        <Col>
          <Button
            className="menu"
            type="ghost"
            icon={<MenuOutlined />}
            onClick={() =>
              setIsSiderBarCollapsed(!globalConfig.isSiderBarCollapsed)
            }
          />
        </Col>
        <Col>
          <Dropdown overlay={<AccountActions />} trigger={["click"]}>
            <Button type="primary" shape="circle" icon={<UserOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}

export default MainHeader;

export function AccountActions() {
  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  return (
    <Menu className="border">
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => handleLogout()}
      >
        Logout
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  );
}
