import { TOKEN_KEY } from "config/Constants";
import { IToken } from "interfaces/token";

class StorageClass {
  private _storage = window.localStorage;

  getToken = () => {
    try {
      const token = this._storage.getItem(TOKEN_KEY);
      if (token != null) {
        return JSON.parse(token) as IToken;
      }
      return null;
    } catch (error) {
      console.log("Storage get: Failed to get token from local storage", error);
      return null;
    }
  };

  setToken = (token: IToken) => {
    try {
      this._storage.setItem(TOKEN_KEY, JSON.stringify(token));
    } catch (error) {
      console.log("Storage get: Failed to set data in local storage", error);
    }
  };

  removeToken = () => {
    this._storage.removeItem(TOKEN_KEY);
  };
}

const Storage = new StorageClass();
Object.freeze(Storage);
export default Storage;
